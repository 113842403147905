import React, {Component} from 'react';
import TOS from './tos/tos';
import Privacy from './privacy/Privacy';
import {Routes, Route} from 'react-router-dom'
import Privacy20220116 from "./privacy/privacy20220116";
import Guide from './data-guide/guide';
import {Link} from 'react-router-dom';
import './PrivacyApp.scss';
import {observer} from "mobx-react";
import {action, makeObservable, observable} from "mobx";
//@ts-ignore
import LOGO from './assets/privacylogo.svg';
import Tos20210924 from "./tos/tos20210924";
import Tos20160701 from "./tos/tos20160701";
import Privacy20230416 from "./privacy/privacy20230416";
import Privacy20230831 from "./privacy/privacy20230831";
import Privacy20241114 from "./privacy/privacy20241114";
import Privacy20241115Start from "./privacy/privacy20241115Start";


@observer
class PrivacyApp extends Component {

    @observable
    private isTogglerOpen: boolean = true;

    @observable
    private selectedValue: string = '';

    constructor(props: any) {
        super(props);
        makeObservable(this);
    }

    @action
    private clickNavToggler = () => {
        this.isTogglerOpen = !this.isTogglerOpen;
    };

    private clickNavMenu = (e: React.MouseEvent<HTMLUListElement>) => {
        this.selectedValue = (e.target as Element).id;
    };

    @action
    private setPath = (path: string) => {
        this.selectedValue = path;
    };

    render() {
        return (
            <article>
                <nav className="nav-policy">
                    <div className="container">
                        <a className="logo" href="https://www.mobileindex.com"
                           rel="noreferrer" target={"_blank"}>
                            <img alt="logo" src={LOGO}/>
                        </a>
                        <div className="nav-toggler" onClick={this.clickNavToggler}>
                            <span className="nav-toggler-icon"></span>
                            <span className="nav-toggler-icon"></span>
                            <span className="nav-toggler-icon"></span>
                        </div>
                        <div
                            className={`nav-collapse collapse ${this.isTogglerOpen ? 'show' : ''} ${this.selectedValue === 'tos' ? 'tos-active' : ''}`}>
                            <ul className="nav-menu-list" onClick={this.clickNavMenu}>
                                <li className={`nav-menu-item ${this.selectedValue === 'tos' ? 'active' : ''}`}><Link
                                    id="tos" to={'/tos'}>이용약관</Link></li>
                                <li className={`nav-menu-item ${this.selectedValue === 'privacy' ? 'active' : ''}`}>
                                    <Link id="privacy" to={'/privacy'}>개인정보처리방침</Link></li>
                                <li className={`nav-menu-item ${this.selectedValue === 'guide' ? 'active' : ''}`}><Link
                                    id="guide" to={'/guide'}>데이터활용 가이드라인</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className={"privacy-body"}>
                    <Routes>
                        <Route path={'/privacy/20220116'}
                               element={<Privacy20220116 pathname={this.setPath}/>}/>
                        <Route path={'/privacy/20230416'}
                               element={<Privacy20230416 pathname={this.setPath}/>}/>
                        <Route path={'/privacy/20230831'}
                               element={<Privacy20230831 pathname={this.setPath}/>}/>
                        <Route path={'/privacy/20241114'}
                               element={<Privacy20241114 pathname={this.setPath}/>}/>
                        <Route path={'/privacy/20241115/s'}
                               element={<Privacy20241115Start pathname={this.setPath}/>}/>
                        <Route path={'/tos/20210924'} element={<Tos20210924 pathname={this.setPath}/>}/>
                        <Route path={'/tos/20160701'} element={<Tos20160701 pathname={this.setPath}/>}/>
                        <Route path={'/tos'} element={<TOS pathname={this.setPath}/>}/>
                        <Route path={'/guide'} element={<Guide pathname={this.setPath}/>}/>
                        <Route path={"*"} element={<Privacy pathname={this.setPath}/>}/>
                    </Routes>
                </div>

                <footer className="footer-policy">
                    <div className="container">
                        <div className="align-item">
                            <div className="copyright-info">
                                © IGAWorks Inc. All rights reserved.
                            </div>
                        </div>
                    </div>
                </footer>

            </article>
        );
    }
}

export default PrivacyApp;