import React, {Component} from 'react';
import {Link} from "react-router-dom";

import './Privacy.scss';

interface PrivacyProps {
    pathname: (v: string) => void;
}

class Privacy extends Component <PrivacyProps> {

    componentDidMount() {
        this.props.pathname('privacy');
    }

    private renderPrivacy = () => {
        return (
            <div className="cont-policy">
                <div className="container">
                    <header className="cont-header">
                        <h3 className="title-policy">주식회사 아이지에이웍스 개인정보처리방침</h3>
                    </header>
                    <div className="wrap-policy-group">
                        <article className="article-policy">
                            <p>주식회사 아이지에이웍스(이하 ‘아이지에이웍스’라 함)는 「개인정보보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
                                처리할 수 있도록 하기
                                위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                                <br/>
                                <br/>
                                본 개인정보처리방침은 2022년 01월 17일부터 적용됩니다.
                            </p>
                        </article>

                        <h4 className={"chapter-title"}>제1장 총칙</h4>
                        <h5 className="title-article">제1조 목적</h5>
                        <article className="article-policy">
                            <p>주식회사 아이지에이웍스(이하 ‘회사’라 한다) 개인정보보호 관리 규정(이하 ‘본 규정’이라 한다)은
                                「개인정보보호법」 제29조와 같은 법 시행령 제30조 그리고 ‘개인정보의 안전성 확보조치 기준’(행정안전부 고시) 등에 따라 개인정보를 처리함에 있어서
                                개인정보가 분실∙도난∙유출∙위조∙변조 또는 훼손되지 아니하도록 안전성 확보에 필요한 기술적∙관리적 및 물리적 안전조치에 관한 사항을 정하는 것을 목적으로
                                한다.</p>
                        </article>
                        <h5 className="title-article">제2조 용어 정의</h5>
                        <article className="article-policy">
                            <p>본 규정에서 사용하는 용어의 뜻은 다음과 같다.</p>
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">‘개인정보’란 살아 있는 개인에 관한 정보로서 성명, 주민등록번호 및 영상 등을 통하여 개인을
                                    알아볼 수 있는 정보(해당 정보만으로는 특정 개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을 포함한다)를 말한다.
                                </li>
                                <li className="basic-number-item">‘처리’란 개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색,
                                    출력, 정정(訂正), 복구, 이용, 제공, 공개, 파기(破棄), 그 밖에 이와 유사한 행위를 말한다.
                                </li>
                                <li className="basic-number-item">‘정보주체’란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그 정보의 주체가 되는 사람을
                                    말한다.
                                </li>
                                <li className="basic-number-item">‘개인정보파일’이란 개인정보를 쉽게 검색할 수 있도록 일정한 규칙에 따라 체계적으로 배열하거나
                                    구성한 개인정보의 집합물(集合物)을 말한다.
                                </li>
                                <li className="basic-number-item">‘개인정보처리자’란 업무를 목적으로 개인정보파일을 운용하기 위하여 스스로 또는 다른 사람을 통하여
                                    개인정보를 처리하는 공공기관, 법인, 단체 및 개인 등을 말한다.
                                </li>
                                <li className="basic-number-item">‘개인정보보호책임자’란 개인정보처리자의 개인정보 처리에 관한 업무를 총괄해서 책임지는 자로서
                                    개인정보보호법 시행령 제32조제2항에 해당하는 자를 말한다.
                                </li>
                                <li className="basic-number-item">‘개인정보보호담당자’란 개인정보처리자의 개인정보를 취급 및 관리하는 담당자를 말한다.</li>
                                <li className="basic-number-item">‘분야별 개인정보보호책임자’란 개인정보처리자의 개인정보 처리에 관한 업무 중 ‘회사’가 분류한
                                    단위(분야)에 한정하여 개인정보 처리에 관한 업무를 총괄해서 책임지는 자를 말 한다.
                                </li>
                                <li className="basic-number-item">‘분야별 개인정보보호담당자’란 개인정보처리자의 개인정보 처리에 관한 업무 중 ‘회사’가 분류한
                                    단위(분야)에 한정하여 개인정보를 취급 및 관리하는 담당자를 말한다.
                                </li>
                                <li className="basic-number-item"> ‘개인정보취급자’란 개인정보처리자의 지휘∙감독을 받아 개인정보를 처리하는 업무를 담당하는
                                    자로서 임직원, 파견근로자, 시간제근로자 등을 말한다.
                                </li>
                                <li className="basic-number-item">‘개인정보처리시스템’이란 데이터베이스시스템 등 개인정보를 처리할 수 있도록 체계적으로 구성한
                                    시스템을 말한다.
                                </li>
                                <li className="basic-number-item">‘비밀번호’란 정보주체 또는 개인정보취급자 등이 개인정보처리시스템, 업무용 컴퓨터 또는
                                    정보통신망 등에 접속할 때 식별자와 함께 입력하여 정당한 접속 권한을 가진 자라는 것을 식별할 수 있도록 시스템에 전달해야 하는 고유의 문자열로서
                                    타인에게 공개되지 않는 정보를 말한다.
                                </li>
                                <li className="basic-number-item">‘정보통신망’이란 「전기통신기본법」 제2조 제2호에 따른 전기통신설비를 이용하거나 전기통신설비와
                                    컴퓨터 및 컴퓨터의 이용기술을 활용하여 정보를 수집∙가공∙저장∙검색∙송신 또는 수신하는 정보통신체계를 말한다.
                                </li>
                                <li className="basic-number-item"> ‘공개된 무선망’이란 불특정 다수가 무선접속장치(AP)를 통하여 인터넷을 이용할 수 있는 망을
                                    말한다.
                                </li>
                                <li className="basic-number-item">‘모바일 기기’란 무선망을 이용할 수 있는 PDA, 스마트폰, 태블릿PC 등(이에 한정하지 않음)
                                    개인정보 처리에 이용되는 휴대용 기기를 말한다.
                                </li>
                                <li className="basic-number-item">‘바이오정보’란 지문, 얼굴, 홍채, 정맥, 음성, 필적 등 개인을 식별할 수 있는 신체적 또는
                                    행동적 특징에 관한 정보로서 그로부터 가공되거나 생성된 정보를 포함한다.
                                </li>
                                <li className="basic-number-item">‘보조저장매체’란 이동형 하드디스크, USB메모리, CD(Compact Disk),
                                    DVD(Digital Versatile Disk) 등(이에 한정하지 않음) 자료를 저장할 수 있는 매체로서 개인정보처리시스템 또는 개인용 컴퓨터 등과
                                    용이하게 연결∙분리할 수 있는 저장매체를 말한다.
                                </li>
                                <li className="basic-number-item">‘내부망’이란 물리적 망분리, 접근 통제시스템 등에 의해 인터넷 구간에서의 접근이 통제 또는
                                    차단되는 구간을 말한다.
                                </li>
                                <li className="basic-number-item">‘접속기록’이란 개인정보취급자 등이 개인정보처리시스템에 접속하여 수행한 업무내역에 대하여
                                    개인정보취급자 등의 계정, 접속일시, 접속지 정보, 처리한 정보주체 정보, 수행업무 등을 전자적으로 기록한 것을 말한다. 이 경우 ‘접속’이란
                                    개인정보처리시스템과 연결되어 데이터 송신 또는 수신이 가능한 상태를 말한다.
                                </li>
                                <li className="basic-number-item">‘관리용 단말기’란 개인정보처리시스템의 관리, 운영, 개발, 보안 등의 목적으로
                                    개인정보처리시스템에 직접 접속하는 단말기를 말한다.
                                </li>
                                <li className="basic-number-item">‘이용자’란 회사가 제공하는 정보통신서비스를 이용하는 자를 말한다.</li>
                                <li className="basic-number-item">‘인증정보’란 개인정보처리시스템 또는 정보통신망을 관리하는 시스템 등이 요구한 식별자의 신원을
                                    검증하는데 사용되는 정보를 말한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제3조 적용 범위</h5>
                        <article className="article-policy">
                            <p>회사가 개인정보를 처리하거나 회사의 개인정보 처리 업무를 위탁 받아 처리하는 수탁자에게 본 개인정보 관리 규정이 적용된다.</p>
                        </article>

                        <h4 className={"chapter-title"}>제2장 개인정보보호 관리 규정의 수립 및 시행</h4>
                        <h5 className="title-article">제4조 개인정보보호 관리 규정의 수립 및 승인</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">개인정보보호책임자는 회사가 개인정보보호와 관련한 법령 및 규정 등을 준수할 수 있도록 내부
                                    의사결정 절차를 통하여 본 규정을 수립하여야 한다.
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 본 규정의 각 사항에 중요한 변경이 있는 경우 이를 즉시 반영 및 수정하여야
                                    한다.
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 본 조 1항 및 2항에 따라 본 규정을 수립하거나 수정하는 경우 대표이사로부터
                                    내부결재 등의 승인을 받아야 하며, 그 이력을 보관∙관리하여야 한다.
                                </li>
                                <li className="basic-number-item">회사는 본 규정의 세부 이행을 위한 각종 지침 등을 마련하여 시행할 수 있으며, 이 경우에도 본
                                    조 3항을 따른다.
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 연 1회 이상으로 본 규정의 이행 실태를 점검∙관리하고 그 결과에 따라 적절한
                                    조치를 취한다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제5조 내부 관리계획의 공표</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">개인정보보호책임자는 제4조 제3항에 따라 승인된 본 규정을 모든 임직원 및 관련자에게 알림으로써
                                    이를 준수하도록 한다.
                                </li>
                                <li className="basic-number-item">본 규정은 임직원 등이 언제든지 열람할 수 있는 방법으로 공개하며, 변경사항이 있는 경우 이를
                                    공지한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제3장 개인정보보호책임자의 역할 및 책임</h4>
                        <h5 className="title-article">제6조 개인정보보호책임자의 지정</h5>
                        <article className="article-policy">
                            <p>회사는 「개인정보 보호법」 제31조와 같은 법 시행령 제32조에 따라 개인정보의 처리에 관한 업무를 총괄하여 책임지는 개인정보보호책임자를 Group H.Q
                                소속 CFO로 정한다.</p>
                        </article>
                        <h5 className="title-article">제7조 개인정보보호책임자의 역할 및 책임</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">
                                    개인정보 보호책임자는 다음 각 호의 업무를 수행한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item"> 개인정보 보호 계획의 수립 및 시행</li>
                                        <li className="circle-number-item"> 개인정보 처리 실태 및 관행의 정기적인 조사 및 개선</li>
                                        <li className="circle-number-item"> 개인정보 처리와 관련한 불만의 처리 및 피해 구제</li>
                                        <li className="circle-number-item"> 개인정보 유출 및 오용∙남용 방지를 위한 내부통제시스템의 구축</li>
                                        <li className="circle-number-item"> 개인정보 보호 교육 계획의 수립 및 시행</li>
                                        <li className="circle-number-item"> 개인정보파일의 보호 및 관리 감독</li>
                                        <li className="circle-number-item"> 「개인정보보호법」 제30조에 따른 개인정보 처리방침의 수립∙변경 및 시행
                                        </li>
                                        <li className="circle-number-item"> 개인정보 보호 관련 자료의 관리</li>
                                        <li className="circle-number-item"> 처리 목적이 달성되거나 보유기간이 지난 개인정보의 파기</li>
                                        <li className="circle-number-item"> 개인정보 침해∙유출 사고 발생 시 대응 총괄</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 제1항의 업무를 수행함에 있어서 필요한 경우 개인정보의 처리 현황, 처리
                                    체계 등에 대하여 수시로 조사하거나 관계 당사자로부터 보고를 받을 수 있다.
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 개인정보보호와 관련하여 이 법 및 다른 관계 법령의 위반 사실을 알게 된
                                    경우에는 즉시 개선조치를 해야 한다.
                                </li>
                                <li className="basic-number-item">분야별 개인정보보호책임자는 ‘회사’가 분류한 단위(분야)에 한정하여 본 조에서 명시한
                                    개인정보보호책임자의 역할 및 책임과 동일하다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제8조 개인정보보호담당자의 역할 및 책임</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">개인정보보호담당자는 제7조에 명시한 개인정보보호책임자의 역할 및 책임을 대리하여 수행할 수
                                    있다.
                                </li>
                                <li className="basic-number-item">분야별 개인정보보호담당자는 ‘회사’가 분류한 단위(분야)에 한정하여 본 조에서 명시한
                                    개인정보보호담당자의 역할 및 책임과 동일하다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제9조 개인정보취급자의 역할 및 책임</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">
                                    개인정보취급자는 회사의 지휘∙감독을 받아 다음 각 호의 업무를 처리하는 자로서 임직원, 계약직, 파견근로자, 시간제근로자 및 계약에 의한
                                    개인정보 처리 위탁 업체(해당 업체 직원 포함) 등을 말한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">개인정보 처리</li>
                                        <li className="circle-number-item">개인정보보호책임자가 위임한 개인정보보호와 관련된 업무</li>
                                        <li className="circle-number-item">개인정보보호책임자에게 개인정보(파일) 등록 신청</li>
                                        <li className="circle-number-item">개인정보(파일) 파기</li>
                                        <li className="circle-number-item">개인정보(파일) 파기 시 개인정보(파일)의 등록사실에 대한 삭제를 개인정보보호책임자에게 요청
                                        </li>
                                        <li className="circle-number-item">개인정보보호 활동 참여</li>
                                        <li className="circle-number-item">본 규정의 준수 및 이행</li>
                                        <li className="circle-number-item">개인정보의 기술적·관리적 보호조치 기준 이행</li>

                                    </ul>
                                </li>
                                <li className="basic-number-item">개인정보취급자는 개인정보를 처리함에 있어서 개인정보가 안전하게 관리될 수 있도록 본 규정과
                                    개인정보보호 관련 법령 및 규정 등을 준수하여야 한다.
                                </li>
                                <li className="basic-number-item">개인정보취급자는 소속직원 또는 제3자에 의한 위법, 부당한 개인정보 침해행위에 대하여 점검하여야
                                    한다. 부서 내 신규 입사자 또는 전입자가 발생하는 경우 부서장은 업무의 특성을 고려한 보안 준수사항을 교육, 지도해야 한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제4장 개인정보보호 교육</h4>
                        <h5 className="title-article">제10조 개인정보보호책임자 및 담당자의 교육</h5>
                        <article className="article-policy">
                            <p>회사는 개인정보 보호책임자를 대상으로 연 1회 이상 개인정보보호와 관련된 교육을 실시한다.</p>
                        </article>
                        <h5 className="title-article">제11조 개인정보보호취급자의 교육</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">
                                    개인정보보호책임자는 개인정보의 적정한 취급을 보장하기 위하여 다음 각 호의 사항을 정하여 개인정보취급자에게 필요한 개인정보보호 교육 계획을 수립하고
                                    실시한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item"> 교육 목적 및 대상</li>
                                        <li className="circle-number-item"> 교육 내용</li>
                                        <li className="circle-number-item"> 교육 일정 및 방법</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">개인정보보호책임자는 제4장에 따라 개인정보 보호 교육을 실시한 결과(수료증, 참석증 등) 또는
                                    이를 입증할 수 있는 관련 자료 등을 기록∙보관한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제5장 기술적 안전조치</h4>
                        <h5 className="title-article">제12조 접근 권한의 관리</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보처리시스템에 대한 접근 권한을 업무 수행에 필요한 최소한의 범위로 업무 담당자에
                                    따라 차등 부여한다.
                                </li>
                                <li className="basic-number-item">회사는 전보 또는 퇴직 등 인사이동이 발생하여 개인정보취급자가 변경되었을 경우 지체없이
                                    개인정보처리시스템의 접근 권한을 변경 또는 말소한다.
                                </li>
                                <li className="basic-number-item">회사는 본 조 1항 및 2항에 의한 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그
                                    기록을 최소 5년간 보관한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보처리시스템에 접속할 수 있는 사용자계정을 발급하는 경우 개인정보취급자 별로
                                    사용자계정을 발급해야 하며, 다른 개인정보취급자와 공유되지 않도록 한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보처리시스템, 인터넷 홈페이지 등에 개인정보취급자 또는 정보주체가 안전한 비밀번호를
                                    설정하여 이행할 수 있도록 다음 각 호의 사항을 적용한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item"> 영대문자, 영소문자, 숫자, 특수문자 중 2종류 이상을 조합하여 최소 10자리
                                            이상 또는 3종류 이상을 조합하여 최소 8자리 이상의 길이로 구성
                                        </li>
                                        <li className="circle-number-item"> 연속적인 숫자나 생일, 전화번호 등 추측하기 쉬운 개인정보 및 아이디와 비슷한
                                            비밀번호 지양
                                        </li>
                                        <li className="circle-number-item"> 비밀번호에 유효기간을 설정하여 분기별 1회 이상 변경</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 권한 있는 개인정보취급자만이 개인정보처리시스템에 접근할 수 있도록 계정정보 또는 비밀번호를
                                    일정 횟수 이상 잘못 입력한 경우 개인정보처리시스템에 대한 접근을 제한하는 등 필요한 기술적 조치를 취해야 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제13조 접근 통제</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 정보통신망을 통한 인가되지 않은 내∙외부의 불법적인 접근 및 침해사고 방지를 위해 다음 각
                                    호의 기능을 포함한 조치를 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">개인정보처리시스템에 대한 접속 권한을 IP(Internet Protocol)주소
                                            등으로 제한하여 인가받지 않은 접근 제한
                                        </li>
                                        <li className="circle-number-item"> 개인정보처리시스템에 접속한 IP주소 등을 분석하여 불법적인 개인정보 유출 시도탐지 및 대응
                                        </li>
                                    </ul>
                                </li>

                                <li className="basic-number-item">회사는 개인정보취급자가 정보통신망을 통해 외부에서 개인정보처리시스템에 접속하려는 경우
                                    가상사설망(VPN : Virtual Private Network) 또는 전용선 등 안전한 접속수단을 적용하거나 안전한 인증수단을 적용한다.
                                </li>
                                <li className="basic-number-item">회사는 취급중인 개인정보가 인터넷 홈페이지, P2P, 공유설정, 공개된 무선망 이용 등을 통하여
                                    열람권한이 없는 자에게 공개되거나 유출되지 않도록 개인정보처리시스템, 업무용 컴퓨터, 모바일 기기 및 관리용 단말기 등에 접근 통제 등에 관한 조치를
                                    하여야 한다.
                                </li>
                                <li className="basic-number-item">회사는 고유식별정보를 처리하는 인터넷 홈페이지를 통해 고유식별정보가 유출∙변조∙훼손되지 않도록
                                    연 1회 이상 취약점을 점검하고 필요한 보완 조치를 한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보처리시스템에 대한 불법적인 접근 및 침해사고 방지를 위해 개인정보취급자가 일정시간
                                    이상 업무처리를 하지 않는 경우 자동으로 시스템 접속이 차단되도록 한다.
                                </li>
                                <li className="basic-number-item">회사에서 별도의 개인정보처리시스템을 이용하지 아니하고 업무용 컴퓨터 또는 모바일 기기를 이용하여
                                    개인정보를 처리하는 경우 제1항을 적용하지 아니할 수 있으며, 이 경우 업무용 컴퓨터 또는 모바일 기기의 운영체제(OS : Operating
                                    System)나 보안프로그램 등에서 제공하는 접근 통제 기능을 이용할 수 있다.
                                </li>
                                <li className="basic-number-item">회사는 업무용 모바일 기기의 분실∙도난 등으로 개인정보가 유출되지 않도록 해당 모바일 기기에
                                    비밀번호 설정 등의 보호조치를 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제14조 개인정보의 암호화</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 고유식별정보, 비밀번호, 바이오정보를 정보통신망을 통하여 송신하거나 보조저장매체 등을
                                    통하여 전달하는 경우 이를 암호화해야 한다.
                                </li>
                                <li className="basic-number-item">회사는 정보통신망을 통해 이용자의 개인정보 및 인증정보를 송·수신할 때에는 안전한 보안서버 구축
                                    등의 조치를 통해 이를 암호화해야 한다. 보안서버는 다음 각 호 중 하나의 기능을 갖추어야 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">웹서버에 SSL(Secure Socket Layer) 인증서를 설치하여 전송하는
                                            정보를 암호화하여 송·수신하는 기능
                                        </li>
                                        <li className="circle-number-item">웹서버에 암호화 응용프로그램을 설치하여 전송하는 정보를 암호화하여 송·수신하는
                                            기능
                                        </li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 비밀번호 및 바이오정보를 암호화하여 저장해야 한다. 단, 비밀번호를 저장하는 경우에는
                                    복호화되지 아니하도록 일방향 암호화(해시함수)하여 저장한다.
                                </li>
                                <li className="basic-number-item">회사는 인터넷 구간 및 인터넷 구간과 내부망의 중간 지점(DMZ : Demilitarized
                                    Zone)에 고유식별정보를 저장하는 경우 이를 암호화해야 한다.
                                </li>
                                <li className="basic-number-item">회사가 내부망에 고유식별정보를 저장하는 경우에는 암호화해야 한다.</li>
                                <li className="basic-number-item">회사는 본 조 1항~5항에 따라 개인정보를 암호화하는 경우 안전한 암호알고리즘으로 암호화하여
                                    저장한다.
                                </li>
                                <li className="basic-number-item">회사는 암호화된 개인정보를 안전하게 보관하기 위하여 안전한 암호 키 생성, 이용, 보관, 배포 및
                                    파기 등에 관한 절차를 수립∙시행한다.
                                </li>
                                <li className="basic-number-item">회사는 이용자의 개인정보를 컴퓨터, 모바일 기기 및 보조저장매체에 저장할 때에는 이를 암호화해야
                                    한다. 특히 업무용 컴퓨터 또는 모바일 기기에 고유식별정보를 저장하여 관리하는 경우 상용 암호화 소프트웨어 또는 안전한 암호화 알고리즘을 사용하여
                                    암호화한 후 저장하여야 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제15조 접속기록의 보관 및 점검</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보취급자가 개인정보처리시스템에 접속한 기록을 1년 이상 보관∙관리한다. 단, 5만명
                                    이상의 정보주체에 관하여 개인정보를 처리하거나, 고유식별정보 또는 민감정보를 처리하는 개인정보처리시스템의 경우에는 2년 이상 보관·관리한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">개인정보취급자 식별 정보(ID 등 계정정보)</li>
                                        <li className="circle-number-item">접속 일시(날짜 및 시간)</li>
                                        <li className="circle-number-item">접속지 정보(접속자의 단말기 정보 또는 IP 주소)</li>
                                        <li className="circle-number-item">처리한 정보주체 정보(정보주체의 이름, ID 등)</li>
                                        <li className="circle-number-item">수행 업무(열람, 수정, 삭제, 인쇄, 입력 등)</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 개인정보의 분실∙도난∙유출∙위조∙변조 또는 훼손 등에 대응하기 위하여 개인정보처리시스템의
                                    접속기록 등을 월 1회 이상 점검하여야 한다. 특히 개인정보를 다운로드한 것이 발견되었을 경우 본 규정으로 정하는 바에 따라 그 사유를 확인하여야
                                    한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보취급자의 접속기록이 위∙변조 및 도난, 분실되지 않도록 해당 접속기록을 별도의
                                    물리적인 저장 장치에 안전하게 보관하여야 하며, 정기적인 백업을 수행하여야한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제16조 악성프로그램 등 방지</h5>
                        <article className="article-policy">
                            <p>회사는 악성프로그램 등을 방지∙치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치∙운영하여야 하며, 다음 각 호의 사항을 준수하여야 한다.</p>
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">보안 프로그램의 자동 업데이트 기능을 사용하거나, 일 1회 이상 업데이트를 실시하여 최신의 상태로
                                    유지
                                </li>
                                <li className="basic-number-item">악성프로그램 관련 경보가 발령된 경우 또는 사용 중인 응용 프로그램이나 운영체제 소프트웨어의
                                    제작업체에서 보안 업데이트 공지가 있는 경우 즉시 이에 따른 업데이트를 실시
                                </li>
                                <li className="basic-number-item">발견된 악성프로그램 등에 대해 삭제 등 대응 조치</li>
                            </ul>
                        </article>
                        <h5 className="title-article">제17조 관리용 단말기의 안전조치</h5>
                        <article className="article-policy">
                            <p>회사는 개인정보 유출 등 개인정보 침해사고 방지를 위하여 관리용 단말기에 대해 다음 각 호의 안전조치를 한다.</p>
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">인가 받지 않은 사람이 관리용 단말기에 접근하여 임의로 조작하지 못하도록 조치</li>
                                <li className="basic-number-item">본래 목적 외로 사용되지 않도록 조치</li>
                                <li className="basic-number-item">악성프로그램 감염 방지 등을 위한 보안조치 적용</li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제6장 관리적 안전조치</h4>
                        <h5 className="title-article">제18조 개인정보 보호조직 구성 및 운영</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보의 안전한 처리를 위하여 다음 각 호의 사항을 포함하는 개인정보보호조직을 구성하고
                                    운영하여야 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">개인정보보호책임자의 지정</li>
                                        <li className="circle-number-item">개인정보보호책임자의 지휘∙감독 하에 개인정보보호책임자의 업무를 지원하는 담당자
                                            지정
                                        </li>
                                        <li className="circle-number-item">개인정보를 처리하는 개인정보취급부서의 지정</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">개인정보보호 조직의 설치, 변경 및 폐지는 대표이사로부터 승인을 받아 정한다.</li>
                                <li className="basic-number-item">개인정보취급부서에서는 개인정보보호 조직과 충분히 협의, 조정하여 개인정보를 처리하여야 한다.
                                </li>
                                <li className="basic-number-item">개인정보보호 조직은 제7조에 따른 업무를 수행하여야 하며, 그 밖에 개인정보의 안전성 확보를
                                    위하여 회사가 필요하다고 판단되는 사항을 수행할 수 있다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제19조 개인정보 유출 사고 대응</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보의 유출 사고 발생 시 신속 대응함으로써 피해 발생을 최소화하기 위해 개인정보 유출
                                    사고 대응 계획을 수립하고 시행한다.
                                </li>
                                <li className="basic-number-item">제1항에 따른 개인정보 유출 사고 대응 계획에는 긴급조치, 유출 통지∙조회 및 신고 절차, 고객
                                    민원 대응조치, 현장 혼잡 최소화 조치, 고객불안 해소조치, 피해자 구제조치 등을 포함한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보 유출에 따른 피해복구 조치 등을 수행함에 있어 정보주체의 불편과 경제적 부담을
                                    최소화할 수 있도록 노력한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제20조 위험도 분석 및 대응</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보가 분실∙도난∙유출∙위조∙변조 또는 훼손되지 아니하도록 위험도 분석을 수행하고
                                    필요한 보안조치 적용 등 대응방안을 마련한다.
                                </li>
                                <li className="basic-number-item">제1항에 따른 위험도 분석은 개인정보 위험도 분석 기준을 활용하거나 위험요소를 식별 및 평가하는
                                    등의 방법으로 수행할 수 있다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제21조 수탁자에 대한 관리 및 감독</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보의 처리 업무를 위탁하는 경우에는 다음 각 호의 사항을 정하여 수탁자를 교육하고
                                    수탁자가 개인정보를 안전하게 처리하는지를 감독한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">교육 및 감독 대상</li>
                                        <li className="circle-number-item">교육 및 감독 내용</li>
                                        <li className="circle-number-item">교육 및 감독 일정, 방법</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 제1항에 따라 수탁자를 교육하고 감독한 결과에 대한 기록을 남기고 문제점이 발견된
                                    경우에는 필요한 보안조치를 한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보의 처리업무를 위탁하는 경우 다음 각 호의 내용이 포함된 문서에 의한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">위탁업무의 목적 및 범위</li>
                                        <li className="circle-number-item">위탁업무 기간</li>
                                        <li className="circle-number-item">재위탁 제한에 관한 사항</li>
                                        <li className="circle-number-item">위탁업무 수행 목적 외 개인정보 처리 금지에 관한 사항</li>
                                        <li className="circle-number-item">개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항</li>
                                        <li className="circle-number-item">위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항
                                        </li>
                                        <li className="circle-number-item">수탁자가 준수하여야 할 의무를 위반한 경우 손해배상 등 책임에 관한 사항</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 개인정보의 처리 업무를 위탁하는 경우 인터넷 홈페이지에 위탁하는 업무의 내용과 수탁자를
                                    공개한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제7장 물리적 안전조치</h4>
                        <h5 className="title-article">제22조 물리적 안전조치</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 있는 경우 이에 대한 출입통제
                                    절차를 수립∙운영하여야 한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관한다.
                                </li>
                                <li className="basic-number-item">회사는 개인정보가 포함된 보조저장매체의 반출∙입 통제를 위한 보안대책을 마련하여야 한다. 다만,
                                    별도의 개인정보처리시스템을 운영하지 아니하고 업무용 컴퓨터 또는 모바일기기를 이용하여 개인정보를 처리하는 경우에는 이를 적용하지 아니할 수 있다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제23조 재해 및 재난 대비 안전조치</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 화재, 홍수, 단전 등의 재해∙재난 발생 시 개인정보처리시스템 보호를 위한 위기대응
                                    매뉴얼 등 대응절차를 마련하고 정기적으로 점검한다.
                                </li>
                                <li className="basic-number-item">회사는 재해∙재난 발생 시 개인정보처리시스템 백업 및 복구를 위한 계획을 마련한다.</li>
                            </ul>
                        </article>
                        <h5 className="title-article">제24조 개인정보의 파기</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 개인정보를 파기할 경우 다음 각 호 중 하나의 조치를 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">완전파괴(소각∙파쇄 등)</li>
                                        <li className="circle-number-item">전용 소자장비를 이용하여 삭제</li>
                                        <li className="circle-number-item">데이터가 복원되지 않도록 초기화 또는 덮어쓰기 수행</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">회사는 개인정보의 일부만을 파기하는 경우, 제1항의 방법으로 파기하는 것이 어려울 때에는 다음
                                    각 호의 조치를 하여야 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">전자적 파일 : 개인정보를 삭제한 후 복구 및 재생되지 않도록 관리 및 감독
                                        </li>
                                        <li className="circle-number-item">제1호 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체 : 해당 부분 마스킹,
                                            천공 등으로 삭제
                                        </li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">각 개인정보 취급자는 각자 개인정보처리시스템 및 업무용으로 사용하는 PC 등 전자기기에서
                                    관리하는 개인정보가 보유기간의 경과, 개인정보의 처리 목적 달성 등 그 개인정보가 불필요하게 되어 파기 대상인지 여부를 수시로 확인 후 파기하고, 그
                                    결과를 월 1회 정기적으로 소속 팀장 또는 부서장에게 보고한다.
                                </li>
                                <li className="basic-number-item">제3항에 따라 보고를 받은 각 팀장 또는 부서장은 파기에 관한 내용을 확인한 후 취합하여
                                    개인정보보호책임자에게 보고한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제8장 가명정보 처리방침</h4>
                        <h5 className="title-article">제25조 가명정보 및 추가정보 관리책임자 지정</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 가명정보의 효율적인 관리 및 보호를 위해 DFN사업실의 부서장을 가명정보 관리책임자로
                                    정한다.
                                </li>
                                <li className="basic-number-item">가명정보 관리책임자는 다음과 같은 역할을 수행한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">가명정보에 대한 내부관리계획의 수립⸱시행</li>
                                        <li className="circle-number-item">내부관리계획의 이행실태 점검 및 관리</li>
                                        <li className="circle-number-item">가명처리 및 적정성 검토 현황 관리</li>
                                        <li className="circle-number-item">가명정보 및 추가정보에 대한 관리⸱감독</li>
                                        <li className="circle-number-item">가명정보 처리 현황 및 관련 기록 관리</li>
                                        <li className="circle-number-item">가명정보를 처리하는 자 교육계획의 수립 및 시행</li>
                                        <li className="circle-number-item">가명처리 및 가명정보 처리 위탁 사항에 대한 관리⸱감독(해당 시)</li>
                                        <li className="circle-number-item">가명정보에 대한 재식별 모니터링 및 재식별 시 처리 방안의 수립⸱시행</li>
                                        <li className="circle-number-item">그 밖의 가명정보 처리에 대한 보호에 관한 사항</li>
                                    </ul>
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제26조 가명정보 및 추가정보의 분리보관</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보는 가명처리가 완료되면 가명처리 전 개인정보와 분리⸱보관하여야 한다.</li>
                                <li className="basic-number-item">가명처리의 과정에서 발생하는 추가정보는 가명정보와 분리⸱보관하여야 한다.</li>
                                <li className="basic-number-item">가명처리 전 개인정보, 가명정보 및 추가정보는 물리적으로 분리 보관하는 것을 원칙으로 하며
                                    물리적 보관이 어려운 경우 논리적인 분리를 시행할 수 있다.
                                </li>
                                <li className="basic-number-item">논리적으로 분리⸱보관하는 경우 엄격한 접근통제를 적용해야 한다.</li>
                            </ul>
                        </article>
                        <h5 className="title-article">제27조 가명정보 및 추가정보에 대한 접근 권한 분리</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명처리가 완료되면 가명정보 또는 추가정보의 접근 권한은 최소한의 인원으로 엄격하게 통제하여야
                                    하며, 업무에 따라 차등적으로 부여하여야 한다.
                                </li>
                                <li className="basic-number-item">추가정보에 대한 접근 권한과 가명정보에 대한 접근 권한은 분리하여 관리해야 한다.</li>
                                <li className="basic-number-item">가명정보 또는 추가정보에 대한 접근권한 부여, 변경 또는 말소에 대한 내역을 기록하도록 하고 이
                                    기록은 최소 3년간 보관하여야 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제28조 가명정보 및 추가정보의 안전성 확보조치</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보와 추가정보는 개인정보보호법 및 동법 시행령에서 요구하는 안전성 확보조치를 수행하여야
                                    한다.
                                </li>
                                <li className="basic-number-item">추가정보에 특별한 이유가 없는 한 생성 즉시 삭제하도록 한다. 단, 시계열 분석 등의 이유로
                                    추가정보가 필요한 경우 저장 시 암호화하여 저장하여야 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제29조 가명정보를 처리하는 자의 교육</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보 관리책임자는 가명정보를 처리하는 자에게 필요한 가명정보 보호 교육계획을 수립하고
                                    실시하여야 한다.
                                </li>
                                <li className="basic-number-item">가명정보 보호 교육은 다음과 같은 내용을 포함하여 시행하여야 한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">가명정보 처리 근거에 관한 사항</li>
                                        <li className="circle-number-item">가명정보 및 추가정보의 안전조치에 관한 사항</li>
                                        <li className="circle-number-item">재식별 금지에 관한 사항</li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">가명정보를 처리하는 자에 대한 교육은 개인정보 보호교육과 함께 수행할 수 있으며 교육을 실시한
                                    결과 또는 이를 입증할 수 있는 관련 자료 등을 기록⸱보관하여야 한다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제30조 가명정보 처리 기록 작성 및 보관</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보의 처리 시 다음과 같은 사항에 대해 가명정보 처리 대장에 기록을 작성하여 보관하여야
                                    한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">가명정보 처리 근거에 관한 사</li>
                                        <li className="circle-number-item">가명처리한 개인정보의 항목</li>
                                        <li className="circle-number-item">가명정보의 이용내역</li>
                                        <li className="circle-number-item">제3자 제공 시 제공받는 자</li>
                                        <li className="circle-number-item">그 밖에 가명정보의 처리 내용을 관리하기 위하여 개인정보보호위원회가 필요하다고 인정하여 고시하는 사항
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제31조 개인정보 처리방침 공개</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보 처리와 관련하여 아래와 같은 내용을 개인정보처리방침에 포함하여 공개하여야한다.
                                    <ul className="list-style-circle-number-list">
                                        <li className="circle-number-item">가명정보의 처리 목적</li>
                                        <li className="circle-number-item">가명정보 처리기간(선택)</li>
                                        <li className="circle-number-item">가명정보 제3자 제공에 관한 사항(해당 시)</li>
                                        <li className="circle-number-item">가명정보 처리 위탁에 관한 사항(해당 시)</li>
                                        <li className="circle-number-item">처리하는 가명정보의 항목</li>
                                        <li className="circle-number-item">가명정보의 안전성 확보조치에 관한 사항</li>

                                    </ul>
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제32조 가명정보의 재식별 금지</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">가명정보를 처리하는 자의 가명정보에 대한 재식별 행위는 엄격하게 금지한다.</li>
                                <li className="basic-number-item">가명정보를 처리하는 자가 가명정보를 처리하는 중 특정 개인에 대한 재식별이 발생하는 경우 즉시
                                    처리를 중단하고 이를 가명정보 관리책임자에게 통보한 후 수립된 재식별 시 처리 방안에 따라 즉시 조치하여야 한다.
                                </li>
                            </ul>
                        </article>

                        <h4 className={"chapter-title"}>제9장 그 밖에 개인정보보호를 위해 필요한 사항</h4>
                        <h5 className="title-article">제33조 개인정보의 목적 외 이용∙제공</h5>
                        <article className="article-policy">
                            <p>회사는 원칙적으로 개인정보를 당초 수집 목적의 범위를 초과하여 이용하거나 제공하여서는 아니된다. 단, 다음 각 호의 어느 하나에 해당되는 경우 정보주체 또는
                                제3자의 이익을 부당하게 침해 할 우려가 있을 때를 제외하고 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공할 수 있다.</p>
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">정보주체 별도 동의를 받은 경우</li>
                                <li className="basic-number-item">다른 법률의 특별한 규정</li>
                                <li className="basic-number-item">명백히 정보주체 또는 제3자의 생명, 신체, 재산의 이익에 필요한 경우</li>
                                <li className="basic-number-item">개인정보를 목적 외 로 이용하거나 제3자에게 제공하지 않으면 다른 법률에서 정하는 소관업무 수행
                                    불가한 경우로 보호위원회의 심의·의결을 거친 경우
                                </li>
                                <li className="basic-number-item">조약, 국제협정 이행을 위해 외국 정부 등 제공에 필요한 경우</li>
                                <li className="basic-number-item">범죄수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
                                <li className="basic-number-item">법원의 재판업무 수행을 위하여 필요한 경우</li>
                                <li className="basic-number-item">형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
                            </ul>
                        </article>

                        <h5 className="title-article">부칙</h5>
                        <article className="article-policy">
                            <p>본 개인정보보호 관리 규정은 2022년 01월 17일부터 적용한다.</p>
                        </article>

                        <article className="article-policy">
                            <p>[이전 개인정보 처리방침]</p>
                            <ul>
                                <li>시행일자 : 2016.05.20 ~ 2017.07.14<a className={"prev-privacy"}
                                                                     rel="noreferrer"
                                                                     href={"https://www.igaworks.com/privacy/160520"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.07.15 ~ 2017.10.31<a className={"prev-privacy"}
                                                                     rel="noreferrer"
                                                                     href={"https://www.igaworks.com/privacy/170715"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.11.01 ~ 2020.07.16<a className={"prev-privacy"}
                                                                     rel="noreferrer"
                                                                     href={"https://www.igaworks.com/privacy/171101"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2020.07.17 ~ 2021.07.31<a className={"prev-privacy"}
                                                                     rel="noreferrer"
                                                                     href={"https://www.igaworks.com/privacy/200717"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2021.08.01 ~ 2022.01.16 <Link className={"prev-privacy"} id="privacy" to={'/privacy/20220116'}>(클릭)</Link></li>
                            </ul>
                        </article>


                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.renderPrivacy()}
            </>
        );
    }
}

export default Privacy;