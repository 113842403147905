import React, {Component} from 'react';
import './Privacy.scss';

interface PrivacyProps {
    pathname:(v:string)=>void;
}

class Privacy extends Component <PrivacyProps> {

    componentDidMount() {
        this.props.pathname('privacy');
    }

    private renderPrivacy = () => {
        return (
            <div className="cont-policy">
                <div className="container">
                    <header className="cont-header">
                        <h3 className="title-policy">주식회사 아이지에이웍스 개인정보처리방침</h3>
                    </header>
                    <div className="wrap-policy-group">
                        <article className="article-policy">
                            <p>주식회사 아이지에이웍스(이하 ‘아이지에이웍스’라 함)는 「개인정보보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
                                처리할 수 있도록 하기
                                위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                                <br/>
                                <br/>
                                본 개인정보처리방침은 2021년 08월 01일부터 적용됩니다.
                            </p>
                        </article>

                        <h5 className="title-article">제1조 (개인정보의 처리 목적)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는
                                「개인정보보호법」
                                제 18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">홈페이지 회원가입 및 관리
                                    <p>
                                        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보
                                        처리 시 법정대리인의
                                        동의 여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                                    </p>
                                </li>
                                <li className="basic-number-item"> 민원사무 처리
                                    <p>
                                        민원인의 신원 확인, 민원사항 확인, 사실 조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                                    </p>
                                </li>
                                <li className="basic-number-item"> 재화 또는 서비스 제공
                                    <p>물품 배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를
                                        처리합니다.</p>
                                </li>
                                <li className="basic-number-item"> 마케팅 및 광고에의 활용
                                    <p>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고
                                        게재 , 서비스의
                                        유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계, 이용자의 관심 및 성향에 기반한 개인 맞춤형 상품 추천 서비스(광고포함) 등을
                                        목적으로
                                        개인정보를 처리합니다.
                                    </p>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제2조 (개인정보의 처리 및 보유기간)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item">아이지에이웍스는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간
                                    내에서 개인정보를 처리·보유합니다.
                                </li>
                                <li className="circle-number-item">
                                    각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                                    <div>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <th>보유정보</th>
                                                <th>근거법령</th>
                                                <th>보유기간</th>
                                            </tr>

                                            <tr>
                                                <td>서비스 이용기록, 접속기록, 접속 IP 정보</td>
                                                <td>통신비밀보호법</td>
                                                <td>3개월</td>
                                            </tr>
                                            <tr>
                                                <td>표시/광고에 관한 기록</td>
                                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                                <td>6개월</td>
                                            </tr>
                                            <tr>
                                                <td>계약 또는 청약철회 등에 관한 기록</td>
                                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                                <td>5년</td>
                                            </tr>
                                            <tr>
                                                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                                <td>5년</td>
                                            </tr>
                                            <tr>
                                                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                                <td>3년</td>
                                            </tr>
                                            <tr>
                                                <td>거래에 관한 장부 및 증빙서류</td>
                                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                                <td>3년</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                                <li className="circle-number-item">
                                    단, 다음의 사유에 해당하는 경우에는 예외로서 해당 사유 종료시까지 개인정보를 처리·보유 합니다.
                                    <ul className="basic-number-item-list">
                                        <li className="basic-number-item"> 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우 해당 수사·조사
                                            종료 시까지
                                        </li>
                                        <li className="basic-number-item"> 서비스 이용에 따른 채권·채무관계 잔존 시 해당 채권·채무관계 정산 시까지</li>
                                    </ul>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제3조 (개인정보의 제3자 제공)</h5>
                        <article className="article-policy">
                            <p>
                                아이지에이웍스는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보보호법」
                                제17조 및 제18조에
                                해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                            </p>
                        </article>

                        <h5 className="title-article">제4조 (개인정보처리 위탁)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 아이지에이웍스는 위탁계약 체결 시 「개인정보보호법」 제26조에 따라 위탁업무 수행목적 외
                                    개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한
                                    관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리
                                    하는지를 감독하고 있습니다.
                                </li>
                                <li className="circle-number-item"> 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록
                                    하겠습니다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제5조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 정보주체는 아이지에이웍스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를
                                    행사할 수 있습니다.
                                </li>
                                <li className="circle-number-item"> 제1항에 따른 권리 행사는 아이지에이웍스에 대해 「개인정보보호법」 시행령 제41조 제1항에
                                    따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며
                                    아이지에이웍스는 이에 대해 지체 없이 조치하겠습니다.
                                </li>
                                <li className="circle-number-item"> 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실
                                    수 있습니다. 이 경우 ‘개인정보 처리 방법에 관한 고시(제2020-7호)’
                                    별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                                </li>
                                <li className="circle-number-item"> 개인정보 열람 및 처리정지 요구는 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여
                                    정보주체의 권리가 제한될 수 있습니다.
                                </li>
                                <li className="circle-number-item"> 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
                                    경우에는 그 삭제를 요구할 수 없습니다.
                                </li>
                                <li className="circle-number-item"> 아이지에이웍스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시
                                    열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제6조 (처리하는 개인정보의 항목 작성)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                            <table>
                                <tbody>
                                <tr>
                                    <th>분류</th>
                                    <th>목적</th>
                                    <th>수집항목</th>
                                    <th>보유기간</th>
                                </tr>

                                <tr>
                                    <td className="col-1">필수</td>
                                    <td rowSpan={2}>
                                        - 홈페이지 회원 가입 및 관리
                                        <br/>
                                        - 민원사무 처리
                                    </td>
                                    <td>아이디, 비밀번호, 성명, 회사명, 이메일, 연락처</td>
                                    <td rowSpan={5}>회원탈퇴 후 5일 이내 또는 법령에 따른 보존기간</td>
                                </tr>
                                <tr>
                                    <td className="col-1">선택</td>
                                    <td>소속부서, 직급/정책</td>
                                </tr>
                                <tr>
                                    <td className="col-1" rowSpan={2}>필수</td>
                                    <td rowSpan={2}>
                                        - 재화 또는 서비스 제공 및 요금정산
                                    </td>
                                    <td>법인) 사업자등록증, 사업자명, 담당 자명, 회사주소, 연락처, 이메일, 예금주 성명, 예금주 거래은행</td>
                                </tr>
                                <tr>
                                    <td>개인) 사업자등록증, 사업자명, 주민 등록번호, 주민등록증사본, 통장사본, 연락처, 이메일, 예금주 성명, 예금주 거래은행</td>
                                </tr>
                                <tr>
                                    <td className="col-1">자동</td>
                                    <td>
                                        - 신규 서비스 개발 및 마케팅/광고에의 활용
                                    </td>
                                    <td>IP주소, 쿠키, 방문기록, 서비스 이용기록, 구매 및 결제기록, 불량 이용기록, 기기 정보
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </article>

                        <h5 className="title-article">제7조 (개인정보의 파기)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 아이지에이웍스는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을
                                    때에는 지체없이 해당 개인정보를 파기합니다.
                                </li>
                                <li className="circle-number-item"> 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른
                                    법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
                                    별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                                    <ul className="basic-number-item-list">
                                        <li className="basic-number-item"> 파기절차 : 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보
                                            파기계획을 수립하여 파기합니다. 파기 사유가 발생한 개인정보(또는
                                            개인정보파일)를 선정하고, 아이지에이웍스는 개인정보 보호책임자의 승인을 받아 개인정보(또는 개인정보파일)를 파기합니다.
                                        </li>
                                        <li className="basic-number-item"> 파기방법 : 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록
                                            파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나
                                            소각하는 등의 방법으로 파기합니다.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제8조 (개인정보의 안전성 확보 조치)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 위조, 변조 또는 훼손되지 않도록
                                    안전성 확보를 위하여 다음과 같은 기술적 조치를 강구하고 있습니다.
                                    <ul className="basic-number-item-list">
                                        <li className="basic-number-item"> 취급하는 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나
                                            파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을
                                            통해 보호되고 있습니다.
                                        </li>
                                        <li className="basic-number-item"> 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고
                                            있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스러운 바이러스가
                                            출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                                        </li>
                                        <li className="basic-number-item"> 암호화 응용프로그램을 설치하여 네트워크상에 개인정보 및 개인인증 정보를 안전하게
                                            전송할 수 있는 보안장치를 채택하고 있습니다.
                                        </li>
                                        <li className="basic-number-item"> 해킹 등 외부침입에 대비하여 침입차단시스템 및 취약점 분석시스템 등을 이용하여
                                            보안에 만전을 기하고 있습니다.
                                        </li>
                                    </ul>
                                </li>
                                <li className="circle-number-item"> 개인정보에 대한 접근권한을 최소로 제한하는 등의 관리적 조치를 취하고 있습니다.</li>
                                <ul className="basic-number-item-list">
                                    <li className="basic-number-item">접근권한 최소한의 인원에 해당하는 자는 다음과 같습니다.
                                        <ul className="parentheses-list">
                                            <li className="parentheses-item"> 이용자를 직접 상대로 하여 업무를 수행하는 자</li>
                                            <li className="parentheses-item"> 업무 담당자 및 상담업무를 수행하는 자</li>
                                            <li className="parentheses-item"> 기타 업무상 개인정보의 취급이 불가피한 자</li>
                                        </ul>
                                    </li>
                                    <li className="basic-number-item"> 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무 등에
                                        관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.
                                    </li>
                                    <li className="basic-number-item"> 전 직원은 입.퇴사 시 정보 보호 서약서 작성을 통하여 사람에 의한 정보유출을 사전에
                                        방지하고 개인정보 처리방침에 대한 이행사항 및 직원의 준수 여부를 감사하기
                                        위한 내부절차를 마련하고 있습니다.
                                    </li>
                                    <li className="basic-number-item"> 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며
                                        입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고
                                        있습니다.
                                    </li>
                                    <li className="basic-number-item"> 전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
                                    <li className="basic-number-item"> 아이지에이웍스는 정보주체 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에
                                        대해서는 책임을 지지 않으므로 정보주체는 아이디(ID)와 비밀번호를
                                        철저히 관리하고 책임을 져야 합니다.
                                    </li>
                                </ul>
                                <li className="circle-number-item"> 아이디(ID) 및 비밀번호관리</li>
                                <ul className="basic-number-item-list">
                                    <li className="basic-number-item"> 아이지에이웍스는 정보주체의 아이디(ID), 비밀번호 도용을 방지하기 위하여 회사의
                                        웹사이트에 연속으로 1년 이상 접속한 이력이 없을 경우 '휴면
                                        아이디(ID)'로 정하여 「고객본인확인 절차를 수행한 후 비밀번호를 신규 등록하도록」로그인을 제한할 수 있습니다.
                                    </li>
                                    <li className="basic-number-item"> 아이지에이웍스는 3개월 단위로 비밀번호를 재설정하도록 조치할 수 있고, 정보주체는 해당
                                        기간 단위로 비밀번호를 재설정할 수 있습니다.
                                    </li>
                                </ul>
                            </ul>
                        </article>

                        <h5 className="title-article">제9조 (개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 아이지에이웍스는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
                                    불러오는 ‘쿠키(cookie)’를 사용합니다.
                                </li>
                                <li className="circle-number-item"> 쿠키는 웹사이트를 운영하는 데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는
                                    소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
                                    <ul className="basic-number-item-list">
                                        <li className="basic-number-item"> 쿠키의 사용 목적 : 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의
                                            취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문
                                            회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 목적으로 사용됩니다.
                                        </li>
                                        <li className="basic-number-item"> 쿠키의 설치•운영 및 거부 : 이용자는 쿠키 설치에 대한 선택권을 갖고 있습니다.
                                            따라서 웹 브라우저 상단의 도구{`>`}인터넷 옵션{`>`}개인정보 메뉴의 옵션
                                            설정에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수
                                            있습니다.
                                        </li>
                                        <li className="basic-number-item"> 단, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                                            있습니다.
                                        </li>
                                        <li className="basic-number-item"> 이용자의 통제권 행사 방법
                                            <p>이용자는 쿠키를 통해 회사가 제공하는 개인 맞춤형 상품 추천서비스를 수신 거부하거나 기타 개인 맞춤 서비스를 차단할 수 있는
                                                선택권을 다음과 같은
                                                방법으로 행사할 수 있습니다. 해당 사항은 브라우저 쿠키와 모바일 기기의 설정을 기반으로 하기 때문에 이를 삭제 및 변경할 경우
                                                모두
                                                초기화됩니다.</p>
                                            <ul className="parentheses-list">
                                                <li className="parentheses-item"> 웹 브라우저 : 인터넷 익스플로러, 크롬, 파이어폭스, 사파리
                                                </li>
                                                <li className="parentheses-item"> 스마트폰 : 아이폰, 안드로이드폰</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제10조 (개인정보 보호책임자)</h5>
                        <article className="article-policy">
                            <ul className="list-style-circle-number-list">
                                <li className="circle-number-item"> 아이지에이웍스는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                                    정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를
                                    지정하고 있습니다.
                                    <div>
                                        <table>
                                            <tbody>
                                            <tr>
                                                <th>구분</th>
                                                <th>부서명</th>
                                                <th>성명</th>
                                                <th>연락처</th>
                                            </tr>
                                            <tr>
                                                <td>총괄 개인정보보호책임자</td>
                                                <td>Group H.Q</td>
                                                <td>박대성</td>
                                                <td>taron.park@igaworks.com</td>
                                            </tr>
                                            <tr>
                                                <td>총괄 개인정보보호담당자</td>
                                                <td>Group H.Q</td>
                                                <td>김창기</td>
                                                <td>henry.kim@igaworks.com</td>
                                            </tr>
                                            <tr>
                                                <td>분야별 개인정보보호책임자</td>
                                                <td>MOBILE INDEX</td>
                                                <td>이철환</td>
                                                <td>hwan@igaworks.com</td>
                                            </tr>
                                            <tr>
                                                <td>분야별 개인정보보호담당자</td>
                                                <td>MOBILE INDEX</td>
                                                <td>김두</td>
                                                <td>gray.kim@igaworks.com</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </li>
                                <li className="circle-number-item"> 정보주체께서는 아이지에이웍스의 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보 보호
                                    관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당자로 문의하실 수 있으며, 보다 원활한 처리를 위해 분야별 개인정보
                                    보호책임자 및 담당자로 문의하실 수 있습니다. 아이지에이웍스는 정보주체의 문의에 대해 지체 없이 답변 및 처리 해드릴 것입니다.
                                </li>
                                <li className="circle-number-item"> 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
                                    개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
                                    바랍니다.
                                    <ul className="basic-number-item-list">
                                        <li className="basic-number-item"> 개인분쟁 조정위원회(www.kopico.go.kr/1833-6972)</li>
                                        <li className="basic-number-item"> 대검찰청 사이버수사과(cybercid@spo.go.kr/국번 없이 1301)
                                        </li>
                                        <li className="basic-number-item"> 경찰청 사이버안전국(cybercureau.police.go.kr/국번 없이
                                            182)
                                        </li>
                                        <li className="basic-number-item"> 개인정보 침해신고센터(http://privacy.kisa.or.kr/국번 없이
                                            118)
                                        </li>
                                    </ul></li>
                            </ul>
                        </article>

                        <h5 className="title-article">제10조 (고지의 의무)</h5>
                        <article className="article-policy">
                            <p>정부의 정책 또는 보안기술의 변경에 따라 내용의 추가ㆍ삭제 및 수정이 있을 시 개정 7일전 회사의 온라인(웹/앱) 등 수단을 통하여 고지할 것입니다.</p>
                        </article>

                        <h5 className="title-article">부칙</h5>
                        <article className="article-policy">
                            <p>본 개인정보처리방침은 2021년 08월 01일부터 시행합니다.</p>
                        </article>

                        <article className="article-policy">
                            <p>[이전 개인정보 처리방침]</p>
                            <ul>
                                <li>시행일자 : 2016.05.20 ~ 2017.07.14<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/160520"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.07.15 ~ 2017.10.31<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/170715"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.11.01 ~ 2020.07.16<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/171101"} target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2020.07.17 ~ 2021.07.31<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/200717"} target="_blank">(클릭)</a>
                                </li>
                            </ul>
                        </article>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.renderPrivacy()}
            </>
        );
    }
}

export default Privacy;