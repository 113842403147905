import React, {Component} from 'react';
import './tos.scss';
import {Link} from "react-router-dom";

interface TOSProps {
    pathname:(v:string)=>void;
}

class Tos extends Component <TOSProps>{

    componentDidMount() {
        this.props.pathname('tos')
    }

    private renderTOS = () => {
        return (
            <div className="cont-policy">
                <div className="container">
                    <header className="cont-header">
                        <h3 className="title-policy">주식회사 아이지에이웍스 이용약관</h3>
                    </header>
                    <div className="wrap-policy-group">
                        <h5 className="title-article">제1조 목적</h5>
                        <article className="article-policy">
                            이 이용약관(이하 “약관” 또는 “본 약관”)은 주식회사 아이지에이웍스(이하 ‘회사’)에서 이용자에게 제공하는
                            모든 온라인서비스(모바일인덱스 서비스와 마케팅클라우드 서비스를 포함하되 이에 국한되지 않음,
                            이하 모든 서비스를 총칭하여 “서비스”라 함)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한
                            사항을 구체적으로 규정함을 목적으로 합니다.
                        </article>

                        <h5 className="title-article">제2조 정의</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">“사이트”란 회사가 서비스의 제공을 위하여 운영하는 온라인 웹사이트를 말하며, 다음 각 호를 포함합니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">모바일인덱스 (<a href={'https://www.mobileindex.com'}>https://www.mobileindex.com</a>)</li>
                                            <li className="hangul-number-item">마케팅클라우드 (<a href={'https://mktcloud.igaworks.com'}>https://mktcloud.igaworks.com</a>)</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="basic-number-item">“비회원”이란 서비스에 대한 회원가입 없이 회사가 발행하는 뉴스레터 메일 수신에 동의하고 메일링 서비스를 신청한 자를 말합니다.</li>
                                <li className="basic-number-item">"회원"이란 회사 홈페이지에 접속하여 약관에 동의하고 서비스의 회원으로 가입한 개인 또는 법인을 말합니다.</li>
                                <li className="basic-number-item">“가입” 또는 “회원가입”이란 회원이 회사가 정한 회원가입 신청 양식에 해당 정보를 기입하고 본 약관에 동의하여 서비스 이용을 위한 등록을 완료하는 행위를 말합니다.</li>
                                <li className="basic-number-item">“서비스계약”이란 회원의 회원가입 신청 및 회사의 승낙으로써 회사와 회원 간에 성립되는 계약을 말합니다.</li>
                                <li className="basic-number-item">“유료서비스”란 회원이 서비스에서 유료로 구매할 수 있는 상품 또는 서비스를 의미합니다.</li>
                                <li className="basic-number-item">“유료서비스 이용계약”이란 회원이 회사가 제공하는 유료서비스의 구매를 희망하여 회사와 회원 간에 체결된 별도 계약을 말합니다.</li>
                                <li className="basic-number-item">“ID”란 회원 식별과 서비스 이용을 위하여 회원이 신청하고 회사가 승인한 문자와 숫자의 조합을 말합니다.</li>
                                <li className="basic-number-item">“비밀번호”란 ID와 일치된 이용자임을 확인하기 위하여 회원 자신이 설정한 문자와 숫자의 조합을 말합니다.</li>
                                <li className="basic-number-item">“인증키”란 회원의 유료서비스 이용과 관련하여 회원 및 회원이 사이트에 접속하는 장치를 식별하기 위해 회사가 유료서비스 구매 회원에게 부여한 고유한 값을 말한다.</li>
                                <li className="basic-number-item">“서비스정책”이란 “회원” 및 “비회원”가 서비스 이용함에 있어 준수해야 하는 사항이나 회사가 정한 가이드라인 및 서비스 운영 방침을 말하며,
                                    데이터활용 가이드라인(<a href={'https://mobileindex.com/guide'}>https://mobileindex.com/cs/guide</a>)을 포함하나 이에 국한되지 않습니다.</li>
                            </ul>
                        </article>

                        <h5 className="title-article">제3조 서비스의 제공 및 제반사항</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사가 사이트를 통해 회원 및/또는 비회원에게 제공하는 서비스의 범위는 다음과 같습니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">앱 내 구입 관련 추정 데이터</li>
                                            <li className="hangul-number-item">앱 사용량 관련 추정 데이터</li>
                                            <li className="hangul-number-item">기타 본 사이트에서 제공하는 각종 데이터 및 컨텐츠 서비스</li>
                                        </ul>
                                    </div>

                                </li>
                                <li className="basic-number-item">전항에도 불구하고 회사는 서비스에서 제공하는 내용을 서비스의 향상을 위해 또는 서비스 방침 변경에 따라 임의로 변경할 수 있습니다.</li>
                                <li className="basic-number-item">유료서비스는 회사와 유료서비스 이용계약을 체결한 회원에게만 제공됩니다.</li>
                                <li className="basic-number-item">회사가 회원 및/또는 비회원에게 부여한 서비스 이용 권한은 비 독점적이고 양도 및 재 배포가 불가합니다.</li>
                                <li className="basic-number-item">회사는 서비스 제공을 위한 시스템 유지, 보수, 개발 등에 관한 내용과 서비스 판매 보조,
                                    조언 및 회원의 서비스 이용 지원 등의 사항을 제 3자에게 위탁할 수 있습니다.
                                    이 경우 회사는 서비스 위탁 업무를 수행하는 제3자에게 회원의 서비스 이용에 관한 정보를 제공할 수 있으며,
                                    회원 및 비회원은 본 약관에 동의함으로써 이러한 제공에 대해 동의한 것으로 간주됩니다.
                                    단, 회원 또는 비회원의 개인정보가 제공되는 경우에는 개인정보보호법 및 기타 관계 법령을 준수하여 진행합니다.
                                </li>
                                <li className="basic-number-item">회사는 관련 법령의 재·개정, 새로운 서비스로의 교체, 서비스 방침 변경, 또는 기타 회사가 적절하다고 판단하는 사유에 기하여 서비스의 일부 또는 전부를 변경할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제4조 약관의 효력 및 변경</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">약관은 사이트에 공지하거나 또는 전자우편을 통하여 회원 및 비회원에게 통지함으로써 효력이 발생됩니다.</li>
                                <li className="basic-number-item">회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련법을 위배하지 않는 범위에서 약관을 개정할 수 있습니다.</li>
                                <li className="basic-number-item">회사가 본 약관을 개정하고자 할 경우에는 적용일자 및 개정사유를 명시하여
                                    현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전까지 공지 또는 통지합니다.
                                    다만, 회원 및/또는 비회원에게 불리하게 변경되거나 중요한 내용이 개정되는 경우에는 30일 전부터 공지를 진행함과
                                    동시에 일정기간 회원이 가입시 등록한 전자우편주소, 로그인 시 동의창을 통한 공지 등 1개 이상의 수단을 통해 회원 또는
                                    비회원에게 명확하게 공지 또는 통지합니다.
                                </li>
                                <li className="basic-number-item">회사가 전항에 따라 개정약관을 공지 또는 통지하면서
                                    개정된 약관의 개정에 대하여 회원 또는 비회원이 공지 기간 내에 거부의 의사표시를 하지 않으면
                                    약관 개정에 동의한 것으로 본다는 의미를 명확하게 공지 또는 통지하였음에도 회원 또는 비회원이
                                    명시적으로 거부의 의사표시를 하지 아니하였을 경우에는 회원 또는 비회원이 개정약관에 동의한 것으로 봅니다.
                                </li>
                                <li className="basic-number-item">회원 또는 비회원이 개정약관의 적용에 동의하지 않는 경우 회사는
                                    개정약관의 내용을 회원 또는 비회원에 적용할 수 없으며, 개정약관에 동의하지 않는 회원 또는 비회원은
                                    개정약관의 적용을 받는 서비스 이용이 제한될 수 있습니다. 개정약관의 적용이 불가피 한 특별한 사정이 있는
                                    경우 회사는 개정약관에 동의하지 않는 회원과의 서비스계약 및/또는 유료서비스 이용계약을 해지할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회원과 회사가 약관에서 정하고 있는 사항에 관하여 약관의 내용과 다르게 합의한 사항이 있을 때에는 그 합의 사항이 약관보다 우선합니다.</li>
                            </ul>
                        </article>
                        <h5 className="title-article">제5조 약관의 준칙</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 본 약관의 세부사항에 대하여 서비스정책을 둘 수 있습니다.</li>
                                <li className="basic-number-item">본 약관에 명시되지 않은 사항에 대해서는 서비스정책 및 관련 법령 등의 규정에 따르며,
                                    약관의 규정과 서비스정책의 내용이 다른 경우 약관의 규정이 우선합니다.</li>
                            </ul>
                        </article>

                        <h5 className="title-article">제6조 회원 또는 비회원에 대한 통지</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사가 회원 및 비회원에 대하여 통지를 하는 경우 본 약관에 별도의
                                    규정이 없는 한 회원 또는 비회원이 제공한 전자우편, (휴대)전화(SMS 포함), 카카오톡 메시지, 각 서비스별 알림 등의 수단으로 할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원 및 비회원 전체에 대한 통지를 하는 경우 7일 이상 각 서비스 별 내 공지 게시판,
                                    알림창 등에 게시함으로써 제1항의 통지를 갈음 할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
                                    대하여는 제1항의 통지를 합니다.
                                </li>
                                <li className="basic-number-item">회원 및 비회원은 회사에 실제로 연락이 가능한 전자우편,
                                    (휴대)전화번호 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며 회사의 통지를 확인하여야 합니다.
                                </li>
                                <li className="basic-number-item">회사는 회원 및 비회원의 연락처 미기재, 변경 후 미수정 등으로 인하여
                                    개별 통지가 어려운 경우에 한하여 제2항 본문의 공지를 함으로써 개별 통지한 것으로 간주하며,
                                    회원 및 비회원의 제3항에 따른 의무를 소홀히 하여 발생한 불이익에 대해서는 보호받지 못합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제7조 회원 가입</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원으로 가입하여 서비스를 이용하고자 하는 자는 이용약관의
                                    내용에 동의하고 회사가 정한 절차에 따른 가입신청을 완료해야 하며, 회사가 회원의 가입신청을 승낙함으로써 서비스계약이 성립합니다.
                                    이때 회원은 회사가 요청하는 필수 정보를 제공하여야 합니다.
                                </li>
                                <li className="basic-number-item">회사는 회사의 사이트를 통해서 제공되는 서비스를 통합하여 운영합니다.
                                    따라서, 회원가입이 완료된 회원은 회사가 통합정책으로 운영하는 사이트에서 동일 ID와 비밀번호로 로그인하여 서비스를 이용할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회원과 비회원은 서비스 이용 시 실명으로 회원가입 또는 뉴스레터 신청합니다.
                                    실명으로 등록하지 않은 회원 또는 비회원은 서비스 이용이나 권리 주장에 제한이 있을 수 있습니다.
                                    회사는 필요에 따라 회원의 실명을 확인하는 조치를 이행 할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 다음 각 호의 어느 하나에 해당하는 경우에는 회원가입신청을 거부할 수 있으며,
                                    등록이 된 이후에도 아래 각 호에 해당하는 경우에는 승낙을 취소 또는 철회할 수 있습니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">타인의 명의를 이용한 경우</li>
                                            <li className="hangul-number-item">사실이 아닌 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                                            <li className="hangul-number-item">본 약관 및 서비스정책 위반 등을 이유로 이전에 회원 자격을 상실한 적이 있거나, 이용 제한 중 회원 탈퇴 후 재가입 신청을 하는 경우</li>
                                            <li className="hangul-number-item">본 약관 및 서비스정책에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</li>
                                            <li className="hangul-number-item">회원의 책임 있는 사유로 인하여 회사가 승낙할 수 없는 경우</li>
                                            <li className="hangul-number-item">1년 이상 서비스 이용기록이 없는 경우</li>
                                            <li className="hangul-number-item">기타 회사가 필요하다고 인정한 경우</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="basic-number-item">회사는 다음과 같은 사유가 있는 경우, 회원가입 신청에 대한 승낙을 유보할 수 있습니다.
                                    본 조항은 유료서비스 이용계약 성립의 경우에도 준용합니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">설비에 여유가 없는 경우</li>
                                            <li className="hangul-number-item">기술상 지장이 있는 경우</li>
                                            <li className="hangul-number-item">기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
                                        </ul>
                                    </div>
                                </li>

                            </ul>
                        </article>

                        <h5 className="title-article">제8조 회원의 ID, 비밀번호, 및 인증키 관리 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 사이트 접속을 위한 ID, 비밀번호, 및 인증키를 제3자로부터 보호하며,
                                    주기적으로 비밀번호를 변경하는 등 ID와 비밀번호의 도용을 방지할 의무가 있습니다.
                                </li>
                                <li className="basic-number-item">회원이 고의로 자신의 ID, 비밀번호, 또는 인증키를 제 3자에게
                                    제공 또는 유출하거나 회사의 별도 사전 서면 승인 없이 제3자로 하여금 서비스를 이용하게 하는 경우
                                    회사는 즉시 해당 회원의 접속권한 차단하거나 서비스 이용을 제한 할 수 있으며, 해당 행동으로 인해 회사에 피해가 발생한 경우
                                    그에 대한 손해 배상을 요구할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원 ID, 비밀번호, 또는 인증키의 불법적 이용을 막기 위해,
                                    동시접속 제한, 접속 PC 지정 등의 서비스 이용 제한 조치를 할 수 있으며, 회원은 회사의 해당 조치에 적극 협조해야 합니다.
                                </li>
                                <li className="basic-number-item">회원은 자신의 ID, 비밀번호, 또는 인증키가 도용되고 있음을 인지한
                                    경우에는 즉시 사이트를 통해 통보하고 사이트에 안내가 있는 경우에는 그에 따라야 합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제9조 회원 정보 변경</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 사이트에서 언제든지 본인의 정보를 열람하고 수정할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회원은 서비스 이용신청 시 기재한 사항이 변경되었을 경우,
                                    사이트에서 수정을 해야 하며 회원의 정보를 변경하지 아니하여 발생되는 문제의 책임은 회원에게 있습니다.
                                </li>
                                <li className="basic-number-item">회원탈퇴를 원하는 경우 회원은 사이트의 1:1문의 또는
                                    이메일(MI_Help@igaworks.com)를 통해 회원탈퇴를 신청해야 합니다.
                                    전술한 규정은 유료서비스 이용계약의 해지 시에도 준용됩니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제10조 회사의 권리와 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 관련 법령 및 약관을 준수하며 선량한 풍속 기타 사회질서에 위반하는 행위를 하지 않습니다.</li>
                                <li className="basic-number-item">회사는 본 약관 및 서비스정책을 필요에 따라 개정 또는 제정할 수 있으며, 이 경우 본 약관 규정에 따라 공지 또는 통지 합니다.
                                </li>
                                <li className="basic-number-item">회사는 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며,
                                    설비에 장애가 생기거나 멸실 된 때에는 지체 없이 이를 수리 복구 합니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스 이용과 관련하여 회원 또는 비회원으로부터 제기된 의견이 정당 하다고 인정될 경우 이를 처리하며,
                                    해당 사이트 내 게시판, 전자우편 등을 통하여 회원에게 처리 과정 및 결과를 전달할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제11조 회원 및 비회원의 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원과 비회원은 약관 및 서비스정책에서 규정하는 사항과 서비스의 이용안내 또는 공지사항 등을 통하여
                                    회사가 공지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
                                </li>
                                <li className="basic-number-item">회원과 비회원은 회사의 명시적인 서면 동의가 없는 한 서비스의 이용권한,
                                    기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                                    회원과 비회원은 본 약관의 의무를 이행하는 과정에서 제3자의 권리나 명예를 침해하지 않도록 최선의 노력을 합니다.
                                </li>
                                <li className="basic-number-item">회원과 비회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 안됩니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">서비스를 통하여 얻은 정보를 임의 가공 또는 변형하거나
                                                저작권법 및 기타 관련 법령을 위반하여 임의로 사용, 출판, 복제 또는 인용하는 행위</li>
                                            <li className="hangul-number-item">서비스를 통하여 얻은 정보를 재판매하거나 기타 영리 목적의 대상으로 활용하는 행위</li>
                                            <li className="hangul-number-item">회사가 제공하지 않은 방식으로 서비스에 접속하지 않고 비정상적인 방법으로 서비스를 이용하거나 시스템에 접근하는 행위</li>
                                            <li className="hangul-number-item">에이전트, 로봇, 스크립트, 스파이더, 스파이웨어 등의 수단을 제작, 배포, 설치하거나 이를 유도하는 행위</li>
                                            <li className="hangul-number-item">회사의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하거나 그러한 내용을 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위</li>
                                            <li className="hangul-number-item">타인을 비방하거나 타인의 명예를 훼손할 목적으로 서비스 또는 서비스를 통해 발생된 정보를 이용하는 행위</li>
                                            <li className="hangul-number-item">회사의 승인을 받지 않고 다른 회원 또는 비회원의 개인정보를 수집 또는 저장하는 행위</li>
                                            <li className="hangul-number-item">회사의 업무에 방해되는 행위 (서버 및 설비의 부하야기, 서비스 부하야기 등)</li>
                                            <li className="hangul-number-item">회사가 제공하는 서비스를 이용하여 동일하거나 유사한 서비스를 개발 또는 판매하는 행위</li>
                                            <li className="hangul-number-item">기타 관련 법령에 위배되는 행위</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제12조 서비스 이용 시간</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">서비스 제공은 회사의 업무상 또는 기술상 특별한 지장이 없는 한
                                    연중무휴, 1일 24시간 운영을 원칙으로 합니다. 그러나 회사는 시스템 정기점검,
                                    보수, 증설 및 교체를 위한 경우, 또는 운영상 상당한 이유가 있는 경우 회사가 정한 날이나
                                    시간에 서비스를 일시 중단 할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은 사이트를
                                    통해 사전에 공지합니다.
                                </li>
                                <li className="basic-number-item">회사는 긴급한 시스템 점검, 보수, 복구 등 부득이한 사유로 인하여
                                    예고 없이 일시적으로 서비스를 중단할 수 있으며, 이 경우 사후에 공지할 수 있습니다.
                                    또한 새로운 서비스로의 교체 등 회사가 적절하다고 판단하는 사유가 있을 경우에는 사이트에 사전 공지 후
                                    현재 제공되는 서비스를 일시적으로 완전히 중단 할 수 있습니다
                                </li>
                                <li className="basic-number-item">회사는 국가비상사태, 정전 등이 발생하였거나 기간통신 사업자가
                                    전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하는 경우,
                                    기타 회사가 통제할 수 없는 불가항력의 사유가 존재하는 경우 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
                                    다만 이 경우 그 사유 및 기간 등을 회원 및 비회원에게 사전 또는 사후에 공지합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제13조 유료서비스 이용계약 성립, 결제 및 해지</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">유료서비스 이용계약은 회사가 회원의 유료서비스 이용 신청을 승낙하거나
                                    회사와 회원 간에 서비스 이용에 관한 별도 계약을 체결함으로 성립합니다.
                                </li>
                                <li className="basic-number-item">유료서비스의 구매는 신용카드, 계좌이체, 휴대폰결제 등 회사가 허용하는 결제방식을 통해서 가능합니다.
                                </li>
                                <li className="basic-number-item">회사는 결제의 이행을 위하여 필요한 회원의 개인정보를 추가적으로 요구할 수 있으며,
                                    회원은 회사가 요구하는 개인정보를 정확하게 제공하여야 합니다.
                                    회사는 회원이 허위로 또는 부정확하게 제공한 개인정보로 인하여 회원에게 발생하는 손해에 대하여 책임을 부담하지 않습니다.
                                </li>
                                <li className="basic-number-item">서비스 이용자와 이용요금 납입자가 다를 경우, 회사는 이를 확인하기
                                    위하여 회원에게 제 증명을 요구하거나 서비스 이용자, 요금 납입자 및 회사 간의 서비스 이용에
                                    관한 서면 합의서를 체결한 후 유료서비스 이용계약을 승낙할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회원은 유료서비스 이용계약을 해지할 경우,
                                    사이트의 1:1문의 또는 이메일(MI_Help@igaworks.com)를 통해 해지 신청하여야 합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제14조 유료서비스의 환불</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">유료서비스 이용계약이 해지되거나 기타 본 약관에 명시된 사유로
                                    회사가 서비스를 제공하지 않게 되는 경우 회원은 회사에 환불을 요청할 수 있으며,
                                    이 경우 회사는 이용요금 전액 또는 잔액을 회원이 지정하는 은행 계좌 또는 결제방식과 동일한
                                    방식으로 환불하며, 동일한 방법으로 환불이 불가능한 경우 회원에게 이를 사전 고지합니다.
                                </li>
                                <li className="basic-number-item">회사는 결제 완료된 유료서비스 이용 금액에서 기 사용요금을
                                    제외한 나머지 금액(환불예정액)을 산정하여 회원에게 고지하고, 회원이 7일 이내에 이견을 제시하지
                                    않으면 환불 신청 후 15일 이내에 회원이 지정하는 은행 계좌 또는 결제방식과 동일한 방식으로 환불합니다.
                                    단, 제17조에 따라 자신의 책임 있는 사유로 서비스 이용계약이 해지되는 경우 회사는
                                    환불수수료(유료서비스 구매금액의 10%)를 환불예정액에서 별도 공제하고 잔액을 환불하며,
                                    이때 환불수수료는 제21조에 따른 회사의 손해배상 청구에 영향을 미치지 아니한다.
                                </li>
                                <li className="basic-number-item">유료서비스 이용 최소기간은 1개월이며, 유료서비스 이용 개시 후
                                    1개월 내에 환불을 요청하는 경우 기 사용요금이 아닌 1개월의 요금을 차감하고 잔액을 환불 합니다.
                                </li>
                                <li className="basic-number-item">회사가 무상으로 지급하는 이벤트 쿠폰 등이 유료서비스 구매에
                                    사용된 경우 해당 쿠폰 등은 환불대상에서 제외하며, 유료서비스 이용계약 해지 시에는 전부 소멸됩니다.
                                </li>
                                <li className="basic-number-item">장기 계약 할인을 받은 유료서비스 이용 회원이 임의로 서비스를
                                    중도 해지하는 경우 이용 기간 동안 받은 할인 혜택에 해당하는 금액은 위약금으로 환불액에서 공제되며,
                                    위약금이 해당 환불액을 초과하는 경우 회원은 초과분을 회사에 지급해야 합니다.
                                </li>
                                <li className="basic-number-item">개인정보(회원ID, 비밀번호, 결제정보 등)의 도용과 결제 사기
                                    등으로 인한 경우에는 환불되지 않습니다. 이 경우 개인정보열람요청은 관련법에 따라 수사기관의
                                    수사자료제공절차에 의해서만 확인이 가능합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제15조 서비스의 폐지</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 서비스의 전부 또는 일부를 폐지할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사가 서비스의 전부 또는 일부를 폐지하는 경우,
                                    회사는 사이트를 통해 사전에 이를 고지하여야 합니다.
                                </li>
                                <li className="basic-number-item">유료 서비스가 폐지되는 경우, 해당 유료서비스를 구매했던 회원은
                                    서비스 폐지 고지일로부터 3년 내에 제14조에 따른 환불을 신청할 수 있으며, 해당 기간 내에 환불이
                                    요청되지 않은 환불금은 기간 만료 시에 자동 소멸됩니다.
                                </li>
                                <li className="basic-number-item">회사는 사전 고지된 서비스의 폐지로 인해 회원 및/또는 비회원
                                    또는 제 3자에게 발생한 손해에 대하여 책임지지 않습니다.</li>
                            </ul>
                        </article>

                        <h5 className="title-article">제16조 정보의 제공</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 회원 및 비회원에게 서비스 이용에 필요가 있다고 인정되는
                                    각종 정보를 통신수단을 이용하여 회원 및 비회원에게 제공할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원
                                    및 비회원의 동의 하에 추가적인 정보를 요구할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제17조 서비스 이용 제한</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 회원 및/또는 비회원이 다음 각 호에 해당하는 행위를 하였을 경우,
                                    사전 통지 없이 회원 및/또는 비회원의 서비스 이용을 제한할 수 있습니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">회원 및/또는 비회원이 약관 및 서비스정책을 위반한 경우</li>
                                            <li className="hangul-number-item">서비스내에 허위 정보를 기재한 경우</li>
                                            <li className="hangul-number-item">회사, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우</li>
                                            <li className="hangul-number-item">법률 기타 선량한 풍속 기타 사회질서에 위반되는 내용을 고의로 유포시킨 경우</li>
                                            <li className="hangul-number-item">회원 및/또는 비회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용하는 경우</li>
                                            <li className="hangul-number-item">서비스 운영을 고의로 방해한 경우</li>
                                            <li className="hangul-number-item">정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                                            <li className="hangul-number-item">서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</li>
                                            <li className="hangul-number-item">부정한 방법으로 서비스에 접속하거나 서비스를 이용하는 경우
                                                (무료서비스를 이용하는 회원이 동일한 ID로 2개 이상의 기기에서 동시접속하거나 유료서비스 이용 회원이 인증키를
                                                무단으로 복제 또는 생성하여 서비스를 이용하는 경우를 포함하나 이에 국한되지 않음)</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="basic-number-item">전 1항에 따라 서비스 이용이 제한된 경우, 회사는 회원 및/또는
                                    비회원에게 제1항에 따라 서비스 이용이 제한된 날로부터 30일 이상의 기간을 정하여 전1항 서비스
                                    이용 제한 사유에 해당하는 사실이 없음을 소명 할 수 있는 기회를 부여합니다. 상기 정한 기한 내에
                                    이용 제한에 해당되는 사유가 없음을 객관적이고 합리적인 근거를 토대로 입증하지 못하는 경우 회사는 이용정지,
                                    회원탈퇴 또는 유료서비스 이용계약의 해지 등의 조치를 이행할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제18조 회원 및 비회원의 개인정보보호</h5>
                        <article className="article-policy">
                            회사는 관련법령이 정하는 바에 따라서 회원 및 비회원의 등록정보를 포함한 회원 및 비회원의 개인정보를 보호하기 위하여 노력합니다.
                        </article>

                        <h5 className="title-article">제19조 지적재산권</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">서비스에 관련된 저작권, 산업재산권 및 기타 권리와 같은 모든
                                    지적재산권은 회사에 귀속됩니다. 라이센스 또는 제 3자에 대한 권리 등은 회원 및/또는 비회원에게
                                    이전되지 않습니다. 회원 또는 비회원은 회사의 동의 없이 상호, 상표 및 로고 등을 사용할 수 없습니다.
                                </li>
                                <li className="basic-number-item">회원 또는 비회원은 서비스와 관련된 어떠한 내용도 임의로 복사,
                                    수정, 가공 등을 하여서는 안되며, 회사 서비스의 지적재산권을 침해하는 어떠한 행위도 하여서는 안됩니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제20조 면책 조항</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사가 천재지변, 전쟁, 내란, 폭동, 화재, 테러,홍수, 지진, 폭풍,
                                    정부의 규제, 해킹, 전염병의 창궐  및 기타 이에 준하는 불가항력 등의 회사가 통제할 수 없는 사유로 인하여
                                    서비스를 제공할 수 없는 경우, 서비스 제공에 대한 책임이 면제됩니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스를 통해 제공된 각종 정보, 자료, 전망, 분석 등의 신뢰성,
                                    완전성, 또는 정확성 등에 대해 어떠한 진술이나 보증도 하지 않습니다. 회사는 어떠한 경우에도 본 약관 및 서비스와
                                    관련하여 회원, 비회원, 또는 제3자에게 발생한 간접 손해, 특별 손해, 결과적 손해, 징벌적 손해, 사업 수익 손실,
                                    사업 정보 손실, 사업 중단 손해, 비용 발생으로 인한 손해 등에 대해 책임을 지지 않으며, 회원/비회원과 제3자 간의
                                    분쟁에 대하여 책임을 지지 않습니다. 이는 회사가 이러한 손해 또는 분쟁에 대한 가능성을 사전에 고지 받은 경우에도 같습니다.
                                </li>
                                <li className="basic-number-item">다음 각 호의 경우 회사는 회원, 비회원 또는 제3자에 대한 본 약관에 따른 책임으로부터 면제됩니다.
                                    <div className="sub-list">
                                        <ul className="list-style-hangul-number-list">
                                            <li className="hangul-number-item">제17조 제1항에 따라 서비스가 제한되는 경우</li>
                                            <li className="hangul-number-item">기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우</li>
                                            <li className="hangul-number-item">회원 또는 비회원의 컴퓨터, 네트워크 등의 전자장비 오류에 의해 손해가 발생한 경우</li>
                                            <li className="hangul-number-item">회원 또는 비회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우, </li>
                                            <li className="hangul-number-item">회원 또는 비회원이 서비스를 이용하여 기대하는 수익 및 효과를 얻지 못한 경우</li>
                                            <li className="hangul-number-item">회사의 고의 또는 중과실이 없이, 회원 또는 비회원에게 무료로 제공된 서비스의 이용과 관련하여 손해가 발생한 경우</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="basic-number-item">본 조의 규정에도 불구하고 회사의 책임이 인정되는 경우 회사가
                                    부담하는 책임의 총액은 직전 6개월 간의 유료서비스 이용금액으로 제한되며, 관련 법령에 따라 회사의
                                    책임이 인정되는 경우에는 법률이 정한 최소한의 범위로 그 책임이 제한됩니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제 21조 배상</h5>
                        <article className="article-policy">
                            <p>회원 및/또는 비회원은 자신의 약관 규정 위반 행위에 의해 회사에 손해 및 비용(금전적 손실과 변호 비용 등)이
                                발생하는 경우 해당 손해 및 비용을 회사에 배상하여야 합니다.</p>
                        </article>

                        <h5 className="title-article">제22조 재판관할</h5>
                        <article className="article-policy">
                            <p>회사와 회원 또는 비회원 간에 발생한 서비스 이용에 관한 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 서울 소재의 대한민국의 법원에 제기합니다.</p>
                        </article>


                        <h5 className="title-article">부칙</h5>
                        <article className="article-policy">
                            <p>본 '약관'은 2022년 8월 1일부터 적용됩니다</p>
                            <p>이전의 이용 약관은 아래에서 확인하실 수 있습니다.</p>
                        </article>

                        <article className="article-policy">
                            <p>[이전 개인정보 처리방침]</p>
                            <ul>
                                <li>시행일자 : 2021.09.24 ~ 2022.07.31
                                    <Link className={"prev-privacy"} id="tos" to={'/tos/20210924'}>(클릭)</Link></li>
                                <li>시행일자 : 2016.07.01 ~ 2021.09.23
                                    <Link className={"prev-privacy"} id="tos" to={'/tos/20160701'}>(클릭)</Link></li>
                            </ul>
                        </article>
                    </div>
                </div>
            </div>

        )
    };


    render() {
        return (
            <>
                {this.renderTOS()}
            </>
        );
    }
}

export default Tos;