import React, {Component} from 'react';
import './tos20210924.scss';

interface TOSProps {
    pathname: (v: string) => void;
}

class Tos20210924 extends Component <TOSProps> {

    componentDidMount() {
        this.props.pathname('tos')
    }

    private renderTOS = () => {
        return (
            <div className="cont-policy">
                <div className="container">
                    <header className="cont-header">
                        <h3 className="title-policy">이용약관</h3>
                    </header>
                    <div className="wrap-policy-group">
                        <h5 className="title-article">제1조 목적</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">이 이용약관(이하 ‘약관’)은 주식회사 아이지에이웍스(이하 ‘회사’)에서 이용 고객(이하
                                    ‘회원’)에게 제공하는 모바일인덱스
                                    서비스 (이하 ‘서비스’)의 가입조건 및 이용에 관한 제반 사항과 기타 필요한 사항을 구체적으로 규정함을 목적으로 합니다.
                                </li>
                                <li className="basic-number-item">회원이 되고자 하는 자가 본 사이트 홈페이지에서 정한 소정의 절차를 거쳐서 "회원가입" 확인버튼을
                                    누르면 약관에 동의하는 것으로 간주합니다. 약관에 정하는 이외의 이용자와 본 회사 홈페이지의 권리, 의무 및 책임사항에 관해서는 전기통신사업법 기타
                                    대한민국의 관련 법령과 상관습에 의합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제2조 정의</h5>
                        <article className="article-policy">
                            <p>"회원"이란 본 회사 홈페이지에 접속하여 약관에 동의하고 본 서비스의 회원으로 가입한 개인 또는 법인을 말합니다.
                                <br/>
                                “서비스”란 회사가 웹사이트(https://www.mobileindex.com, 이하 사이트)을 통해 회원에게 제공하는 모든 정보 및 데이터를 말합니다.
                            </p>
                        </article>

                        <h5 className="title-article">제3조 서비스의 제공 및 제반사항</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 사이트를 통해 회원에게 아래와 같은 서비스를 제공합니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">Console Data</li>
                                            <li className="black-circle-item">Market Index</li>
                                            <li className="black-circle-item">App Index</li>
                                            <li className="black-circle-item">기타 본 사이트에서 제공하는 각종 컨텐츠 서비스</li>
                                        </ul>
                                    </div>

                                </li>
                                <li className="basic-number-item">전항에도 불구하고 회사는 서비스에서 제공하는 내용을 서비스의 향상을 위해 또는 회사의 사정에 따라
                                    임의로 변경할 수 있습니다.
                                </li>
                                <li className="basic-number-item">서비스의 일부는 회사가 요구하는 금액을 유료 결제한 고객에게만 한정적으로 제공되며, 유료 서비스의
                                    내용은 회사에 의해 결정 및 변경될 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사가 회원에게 부여한 서비스 이용 권한은 비독점적이고 양도 및 재배포가 불가합니다.</li>
                                <li className="basic-number-item">회원은 자체적으로 서비스에 대한 신뢰성, 정확성, 적법성, 유용성 등을 판단하여야 하며, 회원
                                    자신의 책임에 따라 서비스를 사용해야 합니다.
                                </li>
                                <li className="basic-number-item">회원은 동일하거나 유사한 서비스를 개발 또는 판매할 수 없습니다</li>
                                <li className="basic-number-item">회사는 서비스 제공을 위한 시스템 유지, 보수, 개발 등에 관한 내용과 서비스 판매 보조, 조언
                                    및 회원의 서비스 이용지원 등의 사항을 제 3자에게 위탁할 수 있습니다. 이 경우 회사는 서비스 위탁 업무를 수행하는 제 3자에게 업무에 필요한 회원의
                                    정보를 제공할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제4조 약관의 효력 및 변경</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">약관은 사이트 또는 회원의 전자우편을 통하여 공지함으로써 효력이 발생됩니다.</li>
                                <li className="basic-number-item">회사는 약관의 규제에 관한 법률, 정보통신망이용촉진및정보보호등에관한법률 등 관련법을 위배하지 않는
                                    범위에서 약관을 개정할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 사정상 중요한 사유가 발생될 경우, 사전 고지 없이 약관의 내용을 변경할 수 있으며,
                                    변경된 약관은 사이트에 공지합니다.
                                </li>
                                <li className="basic-number-item">회원은 변경된 약관에 대해 거부할 권리가 있습니다. 회원은 변경된 약관이 공지된 후 십오(15)일
                                    이내에 거부의사를 표명할 수 있습니다. 회원이 거부하는 경우, 회사는 당해 회원과의 계약을 해지할 수 있습니다. 만약 회원이, 변경된 약관이 공지된 후
                                    십오(15)일 이내에 거부의사를 표시하지 않는 경우, 동의하는 것으로 간주합니다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제5조 약관의 준칙</h5>
                        <article className="article-policy">
                            <p>약관에 명시되지 아니한 사항에 대해서는 약관의규제등에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률 등 관련법 및 기타 관련 법령의 규정에
                                따릅니다.</p>
                            <p>본 회사 홈페이지는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있고,
                                새로운 서비스로의 교체 기타 본 회사 홈페이지가 적절하다고 판단하는 사유에 기하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.</p>
                        </article>

                        <h5 className="title-article">제6조 계약의 성립</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원이 본 약관을 읽고 동의 버튼을 누르거나 확인 등에 체크하는 방법을 취한 경우 본 약관에
                                    동의한 것으로 간주합니다.
                                </li>
                                <li className="basic-number-item">계약은 서비스 참여를 희망하는, 회원이 약관에 동의 후 참가 신청에 대하여 회사가 승낙함으로써
                                    성립합니다. 다만, 회사가 필요하다고 인정하는 경우 회원에게 별도의 서류를 제출하도록 할 수 있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제7조 서비스 이용 신청</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원으로 가입하여 서비스을 이용하고자 하는 회원은 회사에서 요청하는 제반 정보를 제공하여야
                                    합니다.
                                </li>
                                <li className="basic-number-item">모든 회원은 반드시 회원 본인의 이름을 제공하여야만 서비스을 이용할 수 있으며, 실명으로 등록하지
                                    않은 회원은 일체의 권리를 주장할 수 없습니다.
                                </li>
                                <li className="basic-number-item">회원 가입은 반드시 법인명 또는 개인의 실명으로만 가능하며 회사는 실명확인조치를 할 수
                                    있습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제8조 서비스 이용 신청의 승낙 및 유보</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 제 7조에 따른 이용 신청에 대하여 특별한 사정이 없는 한 이용 신청을 승낙합니다.
                                </li>
                                <li className="basic-number-item">회사는 다음 각 호에 해당하는 경우에는 승인을 거부할 수 있으며, 등록이 된 이후에도 아래 각
                                    호에 해당하는 경우에는 승낙을 취소할 수 있습니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">가입 신청자가 실명이 아니거나 타인의 명의를 이용한 경우</li>
                                            <li className="black-circle-item">허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
                                            </li>
                                            <li className="black-circle-item">회사에 의하여 이용계약이 해지된 날로부터 2개월 이내에 재이용신청을 하는
                                                경우
                                            </li>
                                            <li className="black-circle-item">기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 또는
                                                회원의 책임 있는 사유로 인하여 회사가 승낙할 수 없는 경우
                                            </li>
                                            <li className="black-circle-item">기타 회사가 필요하다고 인정한 경우</li>
                                        </ul>
                                    </div>

                                </li>
                                <li className="basic-number-item">회사는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승낙을 유보할 수 있습니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">설비에 여유가 없는 경우</li>
                                            <li className="black-circle-item">기술상 지장이 있는 경우</li>
                                            <li className="black-circle-item">회사의 실명확인절차에서 실명 여부가 확인되지 않는 경우</li>
                                            <li className="black-circle-item">기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제9조 이용자의 ID 및 비밀번호에 대한 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 제 7조와 제 8조에 따라 서비스 이용 신청시 부여 받은 ID와 비밀번호를 제3자로부터
                                    보호하며, 주기적으로 비밀번호를 변경하는 등 ID와 비밀번호의 도용을 방지할 의무가 있습니다.
                                </li>
                                <li className="basic-number-item">회원이 고의로 자신의 ID와 비밀번호를 제 3자에게 제공 또는 유출한 경우, 회사는 즉시 해당
                                    회원의 접속권한을 차단할 수 있으며, 해당 행동으로 인해 회사에 피해가 발생한 경우 그에대한 손해 배상을 요구할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원 ID 및 비밀번호의 불법적 이용을 막기 위해, 동시접속 제한, 접속 PC 지정 등의
                                    서비스 이용 제한 조치를 할 수 있으며, 회원은 회사의 해당 조치에 적극 협조해야 합니다.
                                </li>
                                <li className="basic-number-item">회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 본
                                    회사 홈페이지에 통보하고 본 회사 홈페이지의 안내가 있는 경우에는 그에 따라야 합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제10조 회원 정보 변경</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 사이트에서 언제든지 본인의 정보를 열람하고 수정할 수 있습니다.</li>
                                <li className="basic-number-item">회원은 서비스 이용신청 시 기재한 사항이 변경되었을 경우, 사이트에서 수정을 해야 하며 회원의
                                    정보를 변경하지 아니하여 발생되는 문제의 책임은 회원에게 있습니다.
                                </li>
                                <li className="basic-number-item">회원이 원하는 경우, 서비스 약관 동의를 철회할 수 있으며, 약관 동의를 철회한 경우, 서비스
                                    이용에 제약이 따를 수 있습니다. 약관 동의의 철회는 사이트의 1:1문의 또는 이메일(MI_Help@igaworks.com)를 통해 해지신청을 하는
                                    것으로 이루어집니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제11조 회사의 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로
                                    서비스를 제공하기 위하여 최선을 다하여 노력합니다.
                                </li>
                                <li className="basic-number-item">회사는 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을 위하여 지속적으로 노력하며,
                                    설비에 장애가 생기거나 멸실 된 때에는 지체 없이 이를 수리 복구하여야 합니다. 다만, 천재지변, 비상사태, 또는 그 밖에 부득이한 경우, 서비스을
                                    일시 중단하거나 중지할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 본 계약의 의무를 이행하는 과정에서 제3자의 권리나 명예를 침해하지 않도록 최선의 노력을
                                    합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제12조 회원의 의무</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 약관에서 규정하는 사항과 서비스의 이용안내 또는 공지사항 등을 통하여 회사가 공지하는
                                    사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
                                </li>
                                <li className="basic-number-item">회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게
                                    양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다. 회원은 본 계약의 의무를 이행하는 과정에서 제3자의 권리나 명예를 침해하지 않도록 최선의
                                    노력을 합니다.
                                </li>
                                <li className="basic-number-item">회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는 안됩니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">서비스을 통하여 얻은 정보를 회사의 사전승낙 없이 복제하거나 이를 출판 및
                                                방송 등에 사용하거나 제 3자에게 제공하는 행위
                                            </li>
                                            <li className="black-circle-item">회사의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하는 내용을
                                                게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위
                                            </li>
                                            <li className="black-circle-item">회사의 승인을 받지 않고 다른 회원의 개인정보를 수집 또는 저장하는 행위
                                            </li>
                                            <li className="black-circle-item">회사의 업무에 방해되는 행위 (서버 및 설비의 부하야기, 서비스 부하야기
                                                등)
                                            </li>
                                            <li className="black-circle-item">기타 관계법령에 위배되는 행위</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제13조 서비스 이용 시간</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을
                                    원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스을 일시 중단할 수 있으며, 예정되어 있는
                                    작업으로 인한 서비스 일시 중단은 사이트를 통해 사전에 공지합니다.
                                </li>
                                <li className="basic-number-item">회사는 긴급한 시스템 점검, 증설 및 교체 등 부득이한 사유로 인하여 예고 없이 일시적으로
                                    서비스을 중단할 수 있으며, 새로운 서비스로 교체 등 회사가 적절하다고 판단하는 사유에 의하여 현재 제공되는 서비스을 완전히 중단할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스의
                                    제공이 불가능할 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 이 경우, 그 사유 및 기간 등을 사이트에 사전 또는 사후에
                                    공지합니다.
                                </li>
                                <li className="basic-number-item">회사는 회사가 통제할 수 없는 사유로 인해 서비스을 중단 할 경우(시스템관리자의 고의, 과실 없는
                                    디스크장애, 시스템다운 등), 또는 사전통지가 불가능한 타인(기간통신사업자 등)의 고의, 과실로 인한 시스템 중단 등의 경우, 통지하지 않습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제14조 유료 서비스 결제</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사가 서비스 중 유료서비스로 설정한 콘텐츠 및 정보의 "구매" 대금지급방법은 다음 각 호의 방법
                                    중 가능한 방법으로 할 수 있습니다. 회사는 전자적 지급방법에 의한 대금지급이 이뤄진 경우 회원에게 구매내역의 열람 방법을 제공하며, 회원이 회사가
                                    제공하는 결제내역 알림 서비스에 통지가능한 이메일을 입력하여 결제 내역 수신 의사를 밝힌 경우 전자문서의 송부 방식으로 개별적인 전자적 대금지급 사실을
                                    통지하거나 표시합니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
                                            <li className="black-circle-item">선불카드, 직불카드, 신용카드 등의 각종 카드결제</li>
                                            <li className="black-circle-item">온라인무통장입금</li>
                                            <li className="black-circle-item">전자화폐에 의한 결제</li>
                                            <li className="black-circle-item">"회사"가 지급한 포인트에 의한 결제</li>
                                            <li className="black-circle-item">"회사"와 계약을 맺었거나 "회사"가 인정한 상품권에 의한 결제</li>
                                            <li className="black-circle-item">전화 또는 휴대전화를 이용한 결제</li>
                                            <li className="black-circle-item">기타 전자적 지급방법에 의한 대금지급 등</li>
                                        </ul>
                                    </div>

                                </li>
                                <li className="basic-number-item">전항에도 불구하고 대금지급방법은 회사의 사정에 의해 회사가 임의로 추가 또는 배제할 수
                                    있습니다.
                                </li>
                                <li className="basic-number-item">회원은 본 약관과 관련법에 의하여 정해진 경우를 제외하고 회원 본인의 결제정보에 대한 관리책임이
                                    있으며, 회원 본인의 결제정보를 제3자가 임의로 이용하게 해서는 안되고 이에 대한 책임은 회원이 부담합니다.
                                </li>
                                <li className="basic-number-item">회사와 회원이 유료서비스의 이용에 대해 별도의 계약을 체결한 경우, 별도 계약 내용이
                                    우선합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제15조 결제대금의 환불</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 다음 각호에 해당되는 경우 회원이 결제한 방법과 동일한 방법으로 환불 받을 수 있습니다.
                                    다만, 회사가 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지합니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">이용권을 구매했으나 서비스를 이용한 이력이 전무하여 그에 대한 책임이
                                                전적으로 회사에 있는 경우(다만, 시스템 점검 등 불가피한 경우 제외)
                                            </li>
                                            <li className="black-circle-item">이용 해지의 경우</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="basic-number-item">회사는 부정한 유료서비스 이용을 방지하기 위하여 회사가 무상으로 지급하는 이벤트 쿠폰 등은
                                    환불하지 않으며, 이용해지 시에는 전액 소멸됩니다.
                                </li>
                                <li className="basic-number-item">개인정보(회원ID,비밀번호,결제정보 등)의 도용과 결제사기 등으로 인한 경우에는 환불되지
                                    않습니다. 이 경우 개인정보열람요청은 관련법에 따라 수사기관의 수사자료제공절차에 의해서만 확인이 가능합니다.
                                </li>
                                <li className="basic-number-item">본조에 의한 환불은 관련법령, 정부정책 등에 따라 제한될 수 있습니다.
                                </li>
                                <li className="basic-number-item">유료서비스 이용회원이 유료서비스를 해지하며 이용금액을 환불받기 위해서는 해지전에 전자우편 또는
                                    전화 등으로 해지신청을 하고 회사의 확인을 하여야 합니다.
                                </li>
                                <li className="basic-number-item">유료서비스 이용금액의 환불정산은 아래의 사항에 준합니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">서비스 특성상 1개월 이내의 서비스에 해당하는 서비스요금은 환불하지
                                                않습니다.
                                            </li>
                                            <li className="black-circle-item">1개월을 초과하는 기간으로 가입한 고객이 중도에 해지할 경우, 회사는
                                                1개월을 초과한 사용기간에 따른 기사용요금 및 각종 취급수수료 등을 제외한 나머지 금액을 산정하여 고지한 후, 환불 신청후 15일
                                                이내에 회원의 지정 계좌로 환불합니다.
                                            </li>
                                            <li className="black-circle-item">기사용요금은 장기 계약할인, 프로모션 할인 등의 할인혜택이 적용되지 않으며,
                                                해당 상품의 1개월 신청시 요금을 기준으로 하여 사용기간을 일할 계산하여 산정합니다
                                            </li>
                                            <li className="black-circle-item">만일 장기 계약할인을 받은 유료서비스 이용 회원이 중도 해지할 경우, 이용
                                                기간동안 받은 할인 혜택에 해당하는 금액을 회사에 위약금으로 지급해야 합니다.
                                            </li>
                                        </ul>
                                    </div>

                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제16조 과오금의 환불</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 과오금이 발생한 경우 회원이 결제한 방법과 동일한 방법으로 환불하며, 동일한 방법으로
                                    환불이 불가능할 때에는 이를 사전에 고지합니다.
                                </li>
                                <li className="basic-number-item">"회사"의 책임 있는 사유로 과오금이 발생한 경우 "회사"는 계약비용, 수수료 등에 관계없이
                                    과오금 전액을 환불합니다. 다만, 회원의 책임 있는 사유로 과오금이 발생한 경우, "회사"가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서
                                    회원이 부담하여야 합니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제17조 서비스의 폐지</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 서비스의 전부 또는 일부를 폐지할 수 있습니다.</li>
                                <li className="basic-number-item">회사가 서비스의 전부 또는 일부를 폐지하는 경우, 회사는 사이트를 통해 사전에 이를 고지하여야
                                    합니다.
                                </li>
                                <li className="basic-number-item">유료 서비스가 폐지되는 경우, 해당 유료서비스를 결제했던 회원은, 해당 유료서비스의 이용을
                                    해지하고, 환불을 요구할 수 있으며, 환불은 본 약관 제 15조의 환불 방법에 따릅니다. 단, 이 경우, 중도 해지에 대한 위약금은 발생하지 않습니다.
                                </li>
                                <li className="basic-number-item">회사는 사전 고지된 서비스의 폐지로 인해 회원 또는 제 3자에게 발생한 손해는 책임지지
                                    않습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제18조 정보의 제공</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보를 통신수단을 이용하여 회원에게
                                    제공할 수 있습니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스 개선 및 회원 대상의 서비스 소개 등의 목적으로 회원의 동의 하에 추가적인 정보를
                                    요구할 수 있습니다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제19조 계약 해지 및 서비스 이용 제한</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회원은 이용 계약을 해지할 경우, 사이트의 1:1문의 또는
                                    이메일(MI_Help@igaworks.com)를 통해 해지신청하여야 합니다.
                                </li>
                                <li className="basic-number-item">회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우, 사전통지 없이 이용계약을 해지하거나
                                    또는 기간을 정하여 서비스의 이용을 중지할 수 있습니다.
                                    <div className="sub-list">
                                        <ul className="black-circle-item-list">
                                            <li className="black-circle-item">서비스내에 허위 정보를 기재한 경우</li>
                                            <li className="black-circle-item">회사, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우</li>
                                            <li className="black-circle-item">공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</li>
                                            <li className="black-circle-item">회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는
                                                실행하는 경우
                                            </li>
                                            <li className="black-circle-item">서비스 운영을 고의로 방해한 경우</li>
                                            <li className="black-circle-item">정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스 프로그램
                                                등을 유포하는 경우
                                            </li>
                                            <li className="black-circle-item">서비스을 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나
                                                상업적으로 이용하는 경우
                                            </li>
                                            <li className="black-circle-item">1년 이상 서비스 이용기록이 없는 경우</li>
                                            <li className="black-circle-item">약관을 포함하여 기타 회사가 정한 이용 조건에 위반한 경우</li>
                                        </ul>
                                    </div>

                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제20조 회원의 개인정보보호</h5>
                        <article className="article-policy">
                            <p>본 회사 홈페이지는 관련법령이 정하는 바에 따라서 회원의 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다.</p>
                        </article>

                        <h5 className="title-article">제 21조 지적재산권</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">서비스에 관련된 저작권, 산업재산권 및 기타 권리와 같은 모든 지적재산권은 회사에 귀속됩니다.
                                    라이센스 또는 제 3자에 대한 권리 등은 회원에게 이전할 수 없습니다. 회원은 회사의 동의 없이 상호, 상표 및 로고 등을 사용할 수 없습니다.
                                </li>
                                <li className="basic-number-item">회원은 서비스와 관련된 어떠한 내용도 복사, 수정, 가공 등을 하여서는 안되며, 회사 서비스의
                                    지적재산권을 침해하는 어떠한 행위도 하여서는 안됩니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제22조 면책 조항</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">회사는 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스을 제공할 수 없는 경우,
                                    서비스 제공에 대한 책임이 면제됩니다.
                                </li>
                                <li className="basic-number-item">회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한
                                    경우, 책임이 면제됩니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이
                                    면제됩니다.
                                </li>
                                <li className="basic-number-item">회사는 회원사의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원사가 신상정보 및 전자우편
                                    주소를 부실하게 기재하여 손해가 발생한 경우, 책임을 지지 않습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원사가 서비스을 이용하여 기대하는 수익 및 효과를 얻지 못하거나 상실한 것에 대하여
                                    책임을 지지 않습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원사가 서비스을 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한
                                    회사는 회원사가 서비스을 이용하며 다른 회원사로 인해 입게 되는 정신적 피해에 대하여 책임을 지지 않습니다.
                                </li>
                                <li className="basic-number-item">회사는 서비스를 통해 제공한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을
                                    지지 않습니다.
                                </li>
                                <li className="basic-number-item">회사는 회원사와 제 3자 상호 간에 서비스을 매개로 발생한 분쟁에 대해 개입할 의무가 없으며,
                                    이로 인한 손해를 배상할 책임도 없습니다.
                                </li>
                                <li className="basic-number-item">회사에서 회원사에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한 손해도 책임을 지지
                                    않습니다.
                                </li>

                            </ul>
                        </article>

                        <h5 className="title-article">제23조 배상</h5>
                        <article className="article-policy">
                            <p>회원은 자신의 약관 규정 위반 행위에 의해 회사에 손해 및 비용(금전적 손실과 변호 비용 등)이 발생하는 경우 해당 손해 및 비용을 배상하여야 합니다.</p>
                        </article>

                        <h5 className="title-article">제23조 재판관할</h5>
                        <article className="article-policy">
                            <p>본 회사 홈페이지와 회원간에 발생한 서비스 이용에 관한 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 서울 소재의 대한민국의 법원에 제기합니다.</p>
                        </article>

                        <h5 className="title-article">{"<부칙>"}</h5>
                        <article className="article-policy">
                            <p>약관은 2016. 07. 01. 부터 적용됩니다.</p>
                        </article>
                    </div>
                </div>
            </div>

        )
    };


    render() {
        return (
            <>
                {this.renderTOS()}
            </>
        );
    }
}

export default Tos20210924;