import React, {Component} from 'react';
import {Link} from "react-router-dom";

import './Privacy.scss';
import {observer} from "mobx-react";
import {action, computed, makeObservable, observable} from "mobx";

interface PrivacyProps {
    pathname: (v: string) => void;
}

@observer
class Privacy extends Component <PrivacyProps> {
    @observable
    private width :number = 0;


    constructor(props:any) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.props.pathname('privacy');
        window.addEventListener('resize', this.handleUpdateSize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleUpdateSize);
    }

    @computed
    private get isMobile() {
        return this.width <= 1080
    }

    @action
    private handleUpdateSize = () => {
        if (typeof window === 'object') {
            this.width = window.innerWidth;
        }
    };

    private renderPrivacy = () => {
        return (
            <div className="cont-policy">
                <div className="container">
                    <header className="cont-header">
                        <h3 className="title-policy">주식회사 아이지에이웍스 개인정보처리방침</h3>
                    </header>
                    <div className="wrap-policy-group">
                        <article className="article-policy">
                            <p>주식회사 아이지에이웍스(이하 ‘아이지에이웍스’라 함)는 「개인정보보호법」 및 관계 법령이 정한 바를 준수하여 적법하게 개인정보를 처리하고 안전하게 관리하고
                                있습니다. 이에 「개인정보보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수
                                있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                                <br/>
                                <br/>
                                본 개인정보처리방침은 2023년 09월 01일부터 적용됩니다.
                            </p>
                        </article>

                        <h5 className="title-article">제1조 목적</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 다음의 목적을 위하여 최소한의 개인정보를 수집하여 처리하며, 정보주체의 사생활을 현저히 침해할 우려가 있는 민감정보(사상, 신념, 노동조합, 정당의 가입/탈퇴, 정치적 견해, 건강, 성생활 등에 관한 정보 등)는 수집하지 않습니다.  처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이
                                변경되는 경우에는 「개인정보보호법」 제18조에 따라 정보주체의 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                            <ul className="list-style-hangul-number-list">
                                <li className="hangul-number-item">회원가입 및 회원정보 관리
                                    <p>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인(법인/개인) 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의
                                        개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.</p>
                                </li>
                                <li className="hangul-number-item">재화 또는 서비스 제공 및 민원 접수, 처리 결과 통보 등 민원 사무 처리
                                    <p>서비스(DFINERY, MOBILEINDEX, TRADINGWORKS, GROWTH CONSULTING, MARKETING CLOUD 등 이에 한정하지 않음)제공, 계약서 발송 및 체결, 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</p>
                                </li>
                                <li className="hangul-number-item">요금 정산 처리
                                    <p>아이지에이웍스가 이용자에게 제공한 서비스(DFINERY, MOBILEINDEX, TRADINGWORKS, GROWTH CONSULTING, MARKETING CLOUD 등 이에 한정하지 않음) 이용 요금을 정산하기 위한 목적으로 개인정보를 처리합니다.</p>
                                </li>
                                <li className="hangul-number-item">마케팅 및 광고에의 활용
                                    <p>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고
                                        게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계, 이용자의 관심 및 성향에 기반한 개인맞춤형 상품 추천
                                        서비스(광고 포함) 등을 목적으로 개인정보를 처리합니다.</p>
                                </li>

                            </ul>
                        </article>
                        <h5 className="title-article">제2조 (개인정보의 처리목적, 수집항목, 보유기간)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">아이지에이웍스는 법령에 따른 개인정보 보유 · 이용기간 또는 정보주체로부터 개인정보를 수집 시에
                                    동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리 · 보유합니다.
                                </li>
                                <li className="basic-number-item">각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">관계법령에 의한 경우
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>근거법령</th>
                                                    <th>수집정보</th>
                                                    <th>보유기간</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td rowSpan={2}>통신비밀보호법</td>
                                                    <td className={'left'}>서비스 이용기록, 접속기록, 접속IP정보</td>
                                                    <td>3개월</td>
                                                </tr>
                                                <tr>
                                                    <td className={'left'}>{`그 외의 통신사실확인자료\n(가입자 전기통신일시, 개시/종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료)`}
                                                    </td>
                                                    <td>12개월</td>
                                                </tr>
                                                <tr>
                                                    <td rowSpan={5}>전자상거래 등에서의
                                                        소비자 보호에 관한 법률
                                                    </td>
                                                    <td className={'left'}>표시 / 광고에 관한 기록</td>
                                                    <td>6개월</td>
                                                </tr>
                                                <tr>
                                                    <td className={'left'}>계약 또는 청약철회 등에 관한 기록</td>
                                                    <td rowSpan={2}>5년</td>
                                                </tr>
                                                <tr>
                                                    <td className={'left'}>대금결제 및 재화 등의 공급에 관한 기록</td>
                                                </tr>
                                                <tr>
                                                    <td className={'left'}>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                                    <td rowSpan={2}>3년</td>
                                                </tr>
                                                <tr>
                                                    <td className={'left'}>거래에 관한 장부 및 증빙 서류</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </li>
                                        <li className="hangul-number-item">회사 내부 방침에 의한 경우
                                            <ul className="parentheses-list">
                                                <li className="parentheses-item">회원가입 및 회원정보 관리
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>구분</th>
                                                            <th>수집항목</th>
                                                            <th>보유기간</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>{`디파이너리\n(DFINERY)`}</td>
                                                            <td className={'left'}>(필수) 성함, 이메일</td>
                                                            <td rowSpan={3}>{`회원탈퇴 5일 이내 또는\n관련 법령에 따른\n보존기간 만료 후 파기`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`모바일인덱스\n(MOBILEINDEX)`}</td>
                                                            <td className={'left'}>(필수) 성함, 이메일, 연락처, 회사명, 회사업종, 부서명, 직책</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`마케팅클라우드\n(MARKETING CLOUD)`}</td>
                                                            <td className={'left'}>(필수) 성함, 이메일, 연락처, 회사명, 회사업종, 부서명, 직책</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </li>

                                                <li className="parentheses-item">재화/서비스 제공 및 민원 사무 처리
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>구분</th>
                                                            <th>수집항목</th>
                                                            <th>보유기간</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>{`디파이너리\n(DFINERY)`}</td>
                                                            <td className={'left'}>{`(필수) 성함, 회사 이메일, 회사명, 서비스명\n(선택) 연락처`}</td>
                                                            <td rowSpan={6}>{`회원탈퇴 5일 이내\n또는\n관련 법령에 따른\n보존기간 만료 후\n파기`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`모바일인덱스\n(MOBILEINDEX)`}</td>
                                                            <td className={'left'}>{`(필수) 성함, 이메일, 회사명 또는 팀명\n(선택) 직책, 전화번호`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`트레이딩웍스\n(TRADINGWORKS)`}</td>
                                                            <td className={'left'}>{`(필수) 성함, 이메일, 전화번호, 회사명 또는 팀명\n(선택) 직책`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td rowSpan={2}>{`그로스컨설팅\n(GROWTH CONSULTING)`}</td>
                                                            <td className={'left'}><strong>[퍼포먼스 마케터 과정 신청]</strong>{`\n(필수) 성함, 성별, 이메일, 전화번호, 생년월일, 소속 회사 또는 학교(전공, 학년, 재학상태)\n(선택) 근무/인턴쉽 경험, 포트폴리오, SNS 계정, 웹포트폴리오 URL`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={'left'}><strong>[그로스 마케팅 과정 신청]</strong>{`\n(필수) 성함, 이메일, 전화번호, 생년월일, 연령대, 계좌번호, 주소 \n(선택) 회사명, 직급, 근무연차, 이직/구직 준비 여부, 사내 스카우트 및 채용 계획`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{`마케팅클라우드\n(MARKETING CLOUD)`}</td>
                                                            <td className={'left'}>{`(필수) 성함, 이메일, 회사명 또는 팀명\n(선택) 직책`}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </li>
                                                <li className="parentheses-item">요금 정산 처리
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th style={{width:"20%"}}>구분</th>
                                                            <th>수집항목</th>
                                                            <th>보유기간</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>법인</td>
                                                            <td className={'left'}>{`사업자등록증, 사업자명, 담당자명, 연락처, 회사주소,이메일, 예금주 거래은행, 입금자명`}</td>
                                                            <td rowSpan={2}>{`회원탈퇴 5일 이내\n또는\n관련 법령에 따른\n보존기간 만료 후\n파기`}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>개인</td>
                                                            <td className={'left'}>{`사업자등록증, 사업명, 연락처, 이메일, 통장사본,주민등록번호, 주민등록증사본, 예금주 거래은행, 입금자명`}</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </li>
                                                <li className="parentheses-item">맞춤 콘텐츠 / 마케팅 / 광고에의 활용
                                                    <p>IP주소, 쿠키, 방문접속기록, 서비스 이용기록, 구매 및 결제기록, 불량 이용기록, 기기정보의 행태정보는 서비스 이용 시 자동으로 생성되어 수집되는 개인정보로 아이지에이웍스는 정보주체의 동의 철회 또는 회원 탈퇴 시까지 해당 개인정보를 보유합니다.</p>
                                                </li>
                                            </ul>

                                        </li>
                                    </ul>

                                </li>
                                <li className="basic-number-item">전 항의 내용에도 불구하고 아이지에이웍스는 다음 각 호의 사유에 해당하는 경우에 해당 사유
                                    종료시까지 개인정보를 처리 · 보유합니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">개별적으로 정보주체에게 보유기간에 대한 동의를 받은 경우</li>
                                        <li className="hangul-number-item">관계 법령 위반에 따라 공공기관을 통해 수사, 조사 등이 진행 중인 경우</li>
                                        <li className="hangul-number-item">아이지에이웍스와 이용자간 서비스 이용이 종료되었음에도 채권 · 채무관계가 잔존
                                            중인 경우
                                        </li>
                                        <li className="hangul-number-item">아이지에이웍스와 이용자간 민원, 소송 등 분쟁이 발생한 경우</li>
                                    </ul>
                                </li>
                            </ul>

                        </article>
                        <h5 className="title-article">제3조 (개인정보의 제3자 제공)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 정보주체의 개인정보를 처리 목적으로 명시한 범위를 초과하여 제3자에게 제공하지 않습니다. 다만, 아이지에이웍스는 「개인정보 보호법」,
                                「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「통신비밀보호법」, 「전기통신사업법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「국세기본법」 등
                                다른 법률에 특별한 규정이 있는 경우 또는 재난, 감염병 등 긴급상황 발생 시 법률에서 규정한 적법한 절차에 따라 정보주체의 개인정보를 동의 없이 제공할 수 있습니다.</p>
                            <table>
                                <thead>
                                <tr>
                                    <th>{`제공받은 대상 \n(제3자)`}</th>
                                    <th>목적</th>
                                    <th>제공정보</th>
                                    <th>제공받는 자의 보유기가</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>한국인터넷진흥원</td>
                                    <td>{`스팸신고자 정보제공\n및\n이용제한 업무 처리`}</td>
                                    <td>{`스팸 신고된 고객명, \n주민등록번호/사업자등록번호,\n전화번호`}</td>
                                    <td className={'left'} style={{width:"50%"}}>{`정보 제공일부터 서비스 해지 또는 제공 계약 종료일 중 먼저 도래하는 시점 까지 이용 하며, 이용 기간이 종료 한 시점에 파기. \n\n단 ,다른 법령에 특별한 규정이 있을 경우 관련 법령에 따라 보관.`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </article>

                        <h5 className="title-article">제4조 (개인정보 처리 위탁)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">아이지에이웍스는 원활한 서비스 제공을 위하여 다음과 같이 개인정보 처리업무를 위탁하고
                                    있으며, 위탁업무의 내용이나 수탁기관이 변경될 경우 아이지에이웍스는 본 개인정보 처리방침을 통하여 공개합니다.
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>위탁사업명 및 처리업무의 내용</th>
                                            <th>수탁기관</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>데이터 보관 및 전산시스템 운용 / 관리</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : Amazon Web Service</li>
                                                <li>- 연착처 : aws-korea-privacy@amazon.com</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>홈페이지 이용자 통계 및 서비스 개선</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : Google</li>
                                                <li>- 연락처 : googlekrsupport@google.com</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>CRM(Customer Relationship Management) 솔루션</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : Pipedrive</li>
                                                <li>- 연락처 : privacy@pipedrive.com</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>고객응대</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : Zendesk</li>
                                                <li>- 연락처 : privacy@zendesk.com</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>디파이너리(DFINERY) 이용대금 정산</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : ㈜스텝페이</li>
                                                <li>- 연락처 : contact@steppay.kr</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>카카오톡 비즈메시지(알림톡, 친구톡) 발송</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : ㈜카카오</li>
                                                <li>- 연락처 : 카카오 고객센터 1577-3754 (유료)</li>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>모바일인덱스 (Mobile Index) 이용대금 정산</td>
                                            <td className={'left'}>
                                                <li>- 업체명 : ㈜다날</li>
                                                <li>- 연락처 : help@danal.co.kr</li>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li className="basic-number-item">아이지에이웍스는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라, 다음 각 호의 내용이
                                    포함되도록 계약서 등 문서로 명시하고 있습니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">위탁업무 수행 목적 외 개인정보처리 금지에 관한 사항</li>
                                        <li className="hangul-number-item">개인정보의 기술적·관리적 보호조치에 관한 사항</li>
                                        <li className="hangul-number-item">재위탁 제한에 관한 사항</li>
                                        <li className="hangul-number-item">개인정보의 관리 현황 점검 및 수탁자의 교육에 관한 사항</li>
                                        <li className="hangul-number-item">수탁자 의무 위반 시 손해배상 등 책임에 관한 사항</li>
                                    </ul>
                                </li>

                            </ul>
                        </article>

                        <h5 className="title-article">제4조의2 (개인정보의 재위탁)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 재화 또는 서비스 제공을 위하여 위탁받은 개인정보의 처리 업무의 전부 또는 일부를 제4조에 명시된 수탁기간 및 제3자에게 재위탁 할 수 있으며, 이 경우 아이지에이웍스는 위탁자로부터 사전 동의를 받습니다.</p>
                        </article>
                        <h5 className="title-article">제5조 (개인정보의 국외 이전)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 정보주체의 개인정보를 국외의 다른 사업자에게 제공하지 않습니다. 다만, 정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진 등을 위하여
                                클라우드 서버를 사용함에 따라 다음과 같이 개인정보 처리업무를 국외에 전송하고 있습니다.</p>

                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan={2}></th>
                                    <th colSpan={3}>이전 받는 대상(클라우드 서비스 제공자)</th>
                                </tr>
                                <tr>
                                    <th>구글 (Google)</th>
                                    <th>파이프드라이 (Pipedrive)</th>
                                    <th>젠데스크 (Zendesk)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'tbody-head'}>이전국가</td>
                                    <td colSpan={3}>미국</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>이전일시 및 방법</td>
                                    <td colSpan={3}>서비스 도입 등 기타 문의 신청이 완료되면 클라우드에 보관</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>정보관리책임자 연락처</td>
                                    <td>googlekrsupport@google.com</td>
                                    <td>privacy@pipedrive.com</td>
                                    <td>privacy@zendesk.com</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>이전 목적</td>
                                    <td>홈페이지 이용자 분석 플랫폼 이용</td>
                                    <td>CRM 플랫폼 이용</td>
                                    <td>고객문의처리 플랫폼 이용</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>이전정보 항목</td>
                                    <td>방문일시, 서비스 이용기록, 접속 IP정보, 쿠키</td>
                                    <td colSpan={2}>{`회사명 및 담당자 정보\n(성함, 이메일, 휴대전화번호, 부서, 직함)`}</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>{`이전 받는 자의 개인정보 보유기간`}</td>
                                    <td colSpan={3}>개인정보 처리방침 제2조에 따름</td>
                                </tr>
                                <tr>
                                    <td className={'tbody-head'}>기타</td>
                                    <td colSpan={3}
                                        className={'left'}>{`아이지에이웍스는 클라우드 서비스 제공자의 정보보호 및 개인정보보호 준수 현황을 면밀하게 검토한 이후 클라우드 서비스를 이용하고 있으며클라우드 서비스 제공자는 아이지에이웍스 정보에 접근할 수 없습니다.`}</td>
                                </tr>
                                </tbody>
                            </table>
                        </article>

                        <h5 className="title-article">제6조 (정보주체와 법정대리인의 권리 ∙ 의무 및 행사방법)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">정보주체(만 14세의 경우에는 법정대리인을 말함)는 아이지에이웍스에 대해 언제든지 개인정보 열람,
                                    정정·삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스가 보유하고 있는 개인정보에 대하여 「개인정보 보호법」 제35조에 따라 자신의
                                    개인정보에 대한 열람을 요구할 수 있습니다. 다만, 개인정보 열람 요구 시 「개인정보 보호법」 제35조 제4항에 따라 아래의 경우 열람을 제한할 수
                                    있습니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">법률에 따라 열람이 금지되거나 제한되는 경우</li>
                                        <li className="hangul-number-item">다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을
                                            부당하게 침해할 우려가 있는 경우
                                        </li>
                                        <li className="hangul-number-item">공공기관이 다음 각 목의 어느 하나에 해당하는 업무를 수행할 때 중대한 지장을
                                            초래하는 경우
                                            <ul className="parentheses-list">
                                                <li className="parentheses-item"> 조세의 부과ㆍ징수 또는 환급에 관한 업무</li>
                                                <li className="parentheses-item">「초ㆍ중등교육법」 및 「고등교육법」에 따른 각급 학교, 「평생교육법」에
                                                    따른 평생교육시설, 그 밖의 다른 법률에 따라 설치된 고등교육기관에서의 성적 평가 또는 입학자 선발에 관한 업무
                                                </li>
                                                <li className="parentheses-item"> 학력ㆍ기능 및 채용에 관한 시험, 자격 심사에 관한 업무</li>
                                                <li className="parentheses-item"> 보상금ㆍ급부금 산정 등에 대하여 진행 중인 평가 또는 판단에 관한
                                                    업무
                                                </li>
                                                <li className="parentheses-item"> 다른 법률에 따라 진행 중인 감사 및 조사에 관한 업무</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">정보주체는 개인정보를 직접 정정ㆍ삭제하거나 「개인정보 보호법」 제36조 및 동법 시행령 제43조에
                                    따라 아이지에이웍스에게 정보주체의 개인정보를 정정ㆍ삭제를 요구할 수 있습니다. 다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는
                                    그 삭제를 요구할 수 없습니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스가 보유하고 있는 개인정보에 대하여 「개인정보 보호법」 제37조에 따라 처리정지를
                                    요구할 수 있습니다. 다만, 아래의 경우 법 제37조 제2항에 의하여 처리정지 요구를 거절할 수 있습니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우</li>
                                        <li className="hangul-number-item">다른 사람의 생명·신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을
                                            부당하게 침해할 우려가 있는 경우
                                        </li>
                                        <li className="hangul-number-item">공공기관이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할
                                            수 없는 경우
                                        </li>
                                        <li className="hangul-number-item">개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의
                                            이행이 곤란한 경우로서 정보주체가 그 계약의 해지 의사를 명확하게 밝히지 아니한 경우
                                        </li>
                                    </ul>
                                </li>
                                <li className="basic-number-item">제1항에 따른 정보주체의 권리 행사는 「개인정보 보호법」 시행규칙 별지 제8호 서식에 따라
                                    개인정보(열람, 정정·삭제, 처리정리) 요구서를 작성한 후 서면, 전화, 전자우편, 모사전송(FAX)을 통하여 하실 수 있으며, 아이지에이웍스는 요구를
                                    받은 날부터 빠른 시일내에 조치하도록 하겠습니다.
                                </li>
                                <li className="basic-number-item">제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                                    있습니다. 이 경우 「개인정보 보호법」 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람
                                    등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인할 수 있으며, 만일 열람을 거절하거나 정정ㆍ삭제ㆍ처리정지 요구에 따르지 아니한 경우에도 그 사실
                                    및 이유와 이의제기 방법을 해당 정보주체에게 통지하도록 하겠습니다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제7조 (개인정보의 파기)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성된 경우에는 다음과 같이 해당 개인정보를 파기합니다. 다만, 다른 법령에
                                따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
                            <ul className="list-style-hangul-number-list">
                                <li className="hangul-number-item">파기절차 : 파기하여야 하는 개인정보(또는 개인정보파일)에 대해 개인정보 파기계획을 수립하여
                                    파기합니다. 파기 사유가 발생한 개인정보(또는 개인정보파일)을 선정하고, 아이지에이웍스는 개인정보 보호책임자의 승인을 받아 개인정보(또는
                                    개인정보파일)을 파기합니다.
                                </li>
                                <li className="hangul-number-item">파기방법 : 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이
                                    문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하는 등의 방법으로 파기합니다.
                                </li>
                            </ul>
                        </article>
                        <h5 className="title-article">제8조 (가명정보의 처리)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">아이지에이웍스는 「개인정보 보호법」 제28조의2, 제28조의3에 근거하여 가명정보(개인정보의
                                    일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보 없이는 특정 개인을 알아볼 수 없도록 처리한 정보)를 처리합니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스는 가명정보를 통계작성, 과학적 연구 등의 목적으로 처리하며 이 때 가명정보가
                                    재식별되지 않도록 분리하여 별도 저장, 관리하고 처리 및 보유기간 경과 시 해당 정보를 즉시 파기합니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스는 가명정보를 제3자에게 제공하는 경우, 업무 처리 목적에 필요한 최소한의 가명정보를
                                    제공합니다. 이 경우 가명정보 재식별 금지, 가명정보 및 추가정보의 분리 보관, 임직원 교육 등 안정성 확보를 위한 준수사항이 반영된 계약서를 사용하여
                                    계약을 체결하고 있습니다.
                                </li>
                                <li className="basic-number-item">아이지에이웍스는 가명정보 처리 업무를 위탁하는 경우, 위탁하는 업무의 내용이나 수탁기관 등을
                                    이용자가 확인할 수 있도록 아이지에이웍스 홈페이지에 게시하며, 업무의 내용이나 수탁기관이 변경되는 경우에도 동일한 방법으로 통지합니다. 위탁계약 시
                                    위탁 목적 외 가명처리 금지, 재식별 금지 등 안전한 관리를 위해 필요한 준수사항이 반영된 계약을 체결하고 있으며, 수탁기관이 안전하게 가명정보를
                                    처리하고 있는지에 대해 관리•감독 및 교육을 실시하고 있습니다.
                                </li>

                            </ul>
                        </article>
                        <h5 className="title-article">제9조 (개인정보의 안전성 확보 조치)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 단, 정보주체 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 일들에
                                대해서는 아이지에이웍스는 책임을 지지 않습니다.</p>
                            <ul className="list-style-hangul-number-list">
                                <li className="hangul-number-item">기술적 조치
                                    <ul className="parentheses-list">
                                        <li className="parentheses-item">취급하는 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나
                                            파일 잠금 기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                                        </li>
                                        <li className="parentheses-item">백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고
                                            있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스러운 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가
                                            침해되는 것을 방지하고 있습니다.
                                        </li>
                                        <li className="parentheses-item">암호화 응용프로그램을 설치하여 네트워크 상에 개인정보 및 개인인증 정보를 안전하게
                                            전송할 수 있는 보안장치를 채택하고 있습니다
                                        </li>
                                        <li className="parentheses-item">해킹 등 외부침입에 대비하여 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에
                                            만전을 기하고 있습니다
                                        </li>
                                    </ul>
                                </li>
                                <li className="hangul-number-item">관리적 조치
                                    <ul className="parentheses-list">
                                        <li className="parentheses-item">개인정보 접근 권한의 인원을 ① 이용자를 직접 상대로 하여 업무를 수행하는 자, ②
                                            업무 담당자 및 상담업무를 수행하는 자, ③ 기타 업무상 개인정보의 취급이 불가피한 자로 한정합니다.
                                        </li>
                                        <li className="parentheses-item">개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및 개인정보 보호 의무
                                            등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.
                                        </li>
                                        <li className="parentheses-item">전 직원은 입·퇴사 시 정보 보호 서약서 작성을 통하여 사람에 의한 정보유출을 사전에
                                            방지하고 개인정보 처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
                                        </li>
                                        <li className="parentheses-item">개인정보 관련 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고
                                            있으며 입사 및 퇴사 후 개인정보 사고에 대한 책임을 명확히 하고 있습니다.
                                        </li>
                                        <li className="parentheses-item">전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
                                        <li className="parentheses-item">아이지에이웍스는 정보주체의 아이디(ID), 비밀번호 도용을 방지하기 위하여
                                            아이지에이웍스의 웹사이트에 연속으로 1년 이상 접속한 이력이 없을 경우 '휴면 아이디(ID)'로 정하여 「고객 본인확인 절차를 수행한 후
                                            비밀번호를 신규 등록하도록」 로그인을 제한할 수 있습니다. 이 경우 아이지에이웍스는 ‘휴면 아이디(ID)’ 전환 30일 전까지 정보주체에게 개인정보가 별도 분리되는 사실 및 휴면 예정일 등을 정보주체에게 통지합니다.
                                        </li>
                                        <li className="parentheses-item">아이지에이웍스는 ‘휴면 아이디(ID)’ 개인정보를 별도로 분리하여 보관하여야 하고, 분리 보관된 개인정보는 1년 간 보관 후 복구할 수 없는 방법으로 파기합니다.</li>
                                        <li className="parentheses-item">아이지에이웍스는 3개월 단위로 비밀번호를 재설정 하도록 조치할 수 있고, 정보주체는
                                            해당 기간 단위로 비밀번호를 재설정할 수 있습니다.
                                        </li>
                                    </ul>
                                </li>

                                <li className="hangul-number-item">물리적 조치
                                    <ul className="parentheses-list">
                                        <li className="parentheses-item">전산실 및 자료보관실 등을 통제구역으로 설정하여 출입을 통제합니다.</li>
                                    </ul>
                                </li>

                            </ul>
                        </article>

                        <h5 className="title-article">제10조 (개인정보 자동 수집 장치의 설치·운영 및 그 거부에 관한 사항)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className={'basic-number-item'}>아이지에이웍스는 이용자에게 개별적인 웹사이트 맞춤서비스를 제공하기 위해 이용자의
                                    이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹/모바일 사이트를 운영하는데 이용되는 서버(http)가 이용자의
                                    웹/모바일 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
                                    <ul className="list-style-hangul-number-list">
                                        <li className="hangul-number-item">쿠키의 사용 목적 : 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의
                                            취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
                                            목적으로 사용됩니다.
                                        </li>
                                        <li className="hangul-number-item">쿠키의 설치 · 운영 및 거부 : 회원은 쿠키의 설치 · 운영 및 거부에 대한
                                            선택권을 가짐에 따라 회원이 사용하는 웹 브라우저의 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                                            아니면 모든 쿠키의 저장을 거부할 수 있습니다.
                                        </li>
                                        <li className="hangul-number-item">쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
                                        </li>
                                    </ul>
                                </li>
                                <li className={'basic-number-item'}>이용자는 쿠키를 통해 아이지에이웍스가 제공하는 개인 맞춤형 상품 추천서비스를 수신
                                    거부하거나 기타 개인 맞춤 서비스를 차단할 수 있는 선택권을 다음과 같은 방법으로 행사할 수 있습니다. 해당 사항은 브라우저 쿠키와 모바일 기기의
                                    설정을 기반으로 하기 때문에 이를 삭제 및 변경할 경우 모두 초기화됩니다.
                                    <ul className="parentheses-list">
                                        <li className="parentheses-item">{`Microsoft Edge : 설정 > 쿠키 및 사이트 권한 / 개인정보, 검색 및
                                            서비스`}
                                        </li>
                                        <li className="parentheses-item">{`Chrome : 설정 > 개인 정보 보호 및 보안`}</li>
                                        <li className="parentheses-item">{`Safari : 설정 > 개인정보 보호 > 쿠키 및 웹사이트 데이터`}</li>
                                        <li className="parentheses-item">{`Firefox : 설정 > 개인정보 보호 > 쿠키와 캐시`}</li>
                                        <li className="parentheses-item">{`Android : 설정 > 개인정보 보호 > 광고 > 광고 ID 재설정 또는 광고
                                            ID 삭제`}
                                        </li>
                                        <li className="parentheses-item">{`IOS : 설정 > 개인정보 보호 및 보안 > APPLE 광고 > 개인 맞춤형
                                            광고(OFF)`}
                                        </li>
                                        <li>※ 각 브라우저 및 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</li>

                                    </ul></li>
                            </ul>
                        </article>
                        <h5 className="title-article">제11조 (개인정보 보호책임자)</h5>
                        <article className="article-policy">
                            <ul className="basic-number-item-list">
                                <li className="basic-number-item">아이지에이웍스는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
                                    부서별 개인정보 보호책임자를 지정하고 있습니다.
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th >부서명</th>
                                            <th style={{width:`${this.isMobile?"18%":""}`}}>성명</th>
                                            <th>연락처</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>총괄 개인정보보호책임자</td>
                                            <td>CFO</td>
                                            <td>조영호</td>
                                            <td>Young.cho@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td>총괄 개인정보보호담당자</td>
                                            <td>법무실</td>
                                            <td>최낙훈</td>
                                            <td>Enoch.choi@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={4}>분야별 개인정보보호책임자</td>
                                            <td>그로스컨설팅</td>
                                            <td>이광우</td>
                                            <td>gordon@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td>MI & DMP & 마케팅클라우드</td>
                                            <td>이철환</td>
                                            <td>hwan@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2}>CDP & ATD</td>
                                            <td>박진우</td>
                                            <td>mark@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td>한진원</td>
                                            <td>mike@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={3}>분야별 개인정보보호담당자</td>
                                            <td>그로스컨설팅</td>
                                            <td>위홍복</td>
                                            <td>chandler.wee@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td>MI & DMP & 마케팅클라우드</td>
                                            <td>장창혁</td>
                                            <td>jayden.jang@igaworks.com</td>
                                        </tr>
                                        <tr>
                                            <td>CDP & ATD</td>
                                            <td>김석환</td>
                                            <td>khwan@igaworks.com</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </li>
                                <li className="basic-number-item">정보주체께서는 아이지에이웍스의 서비스(또는 사업)을 수용하시면서 발생한 모든 개인정보 보호 관련
                                    문의, 불만처리, 피해구제 등에 관한 사항을 각 분야별 개인정보 보호책임자에게 문의하실 수 있습니다. 아이지에이웍스는 정보주체의 문의에 대해 지체 없이
                                    답변 및 처리되도록 노력하겠습니다.
                                </li>
                            </ul>
                        </article>

                        <h5 className="title-article">제12조 (개인정보 열람청구)</h5>
                        <article className="article-policy">
                            <p>정보주체는 「개인정보보호법」 제35조 및 동법 시행령 제41조에 따른 개인정보의 열람 청구를 아래의 E-Mail을 통해 할 수 있습니다. 아이지에이웍스는
                                정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
                            <table>
                                <thead>
                                <tr>
                                    <th>개인정보 열람청구 문의 E-Mail</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>contact@igaworks.com</td>
                                </tr>
                                </tbody>
                            </table>

                        </article>
                        <h5 className="title-article">제13조 (권익침해 구제방법)</h5>
                        <article className="article-policy">
                            <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수
                                있습니다. {`\n<아래의 기관은 아이지에이웍스와는 별개의 기관으로서, 아이지에이웍스의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다>`}</p>
                            <table>
                                <thead>
                                <tr>
                                    <th>기관 정보</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'left'}>
                                        <li>▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</li>
                                        <li>{`\t- 소관업무 : 개인정보 침해사실 신고, 상담 신청`}</li>
                                        <li>{`\t- 홈페이지 : http://privacy.kisa.or.kr`}</li>
                                        <li>{`\t- 전화 : (국번없이) 118`}</li>
                                        <li>{`\t- 주소 : (58324) 전남 나주시 진흥길 9 한국인터넷진흥원 개인정보침해신고센터`}</li>
                                        <br/>
                                        <li>▶ 개인정보 분쟁조정위원회</li>
                                        <li>{`\t- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)`}</li>
                                        <li>{`\t- 홈페이지 : 홈페이지 : http://kopico.go.kr`}</li>
                                        <li>{`\t- 전화 : 1833-6972`}</li>
                                        <li>{`\t- 주소 : (03171) 서울시 종로구 세종대로 209 정부서울청사 4층`}</li>
                                        <br/>
                                        <li>▶ 대검찰청 사이버범죄수사단 : (국번없이) 1301 cid@spo.go.kr (www.spo.go.kr)</li>
                                        <br/>
                                        <li>▶ 경찰청 사이버범죄 신고시스템 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </article>
                        <h5 className="title-article">제14조 (고지의 의무)</h5>
                        <article className="article-policy">
                            <p>아이지에이웍스는 정부의 정책 또는 보안 기술의 변경에 따라 내용의 추가 ∙ 삭제 및 수정이 있을 시 개정 7일전 아이지에이웍스의 웹사이트, 앱 등의 온라인 수단을 이용하여 고지합니다.</p>
                        </article>
                        <h5 className="title-article">제15조 (개인정보 처리방침 변경)</h5>
                        <article className="article-policy">
                            <p>이 개인정보 처리방침은 2023년 09월 01일부터 적용됩니다.
                                <br/>
                                이 전의 개인정보 처리방침은 아래에서 확인할 수 있습니다.
                            </p>
                        </article>


                        <article className="article-policy">
                            <p>[이전 개인정보 처리방침]</p>
                            <ul>
                                <li>시행일자 : 2016.05.20 ~ 2017.07.14<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/160520"}
                                                                     target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.07.15 ~ 2017.10.31<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/170715"}
                                                                     target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2017.11.01 ~ 2020.07.16<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/171101"}
                                                                     target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2020.07.17 ~ 2021.07.31<a className={"prev-privacy"}
                                                                     rel={"noreferrer"}
                                                                     href={"https://www.igaworks.com/privacy/200717"}
                                                                     target="_blank">(클릭)</a>
                                </li>
                                <li>시행일자 : 2021.08.01 ~ 2022.01.16 <Link className={"prev-privacy"} id="privacy"
                                                                         rel={"noreferrer"}
                                                                         to={'/privacy/20220116'} target={"_blank"}>(클릭)</Link></li>
                                <li>시행일자 : 2022.01.17 ~ 2023.04.16 <Link className={"prev-privacy"} id="privacy"
                                                                         rel={"noreferrer"}
                                                                         to={'/privacy/20230416'} target={"_blank"}>(클릭)</Link></li>
                                <li>시행일자 : 2023.04.17 ~ 2023.08.31 <Link className={"prev-privacy"} id="privacy"
                                                                         rel={"noreferrer"}
                                                                         to={'/privacy/20230831'} target={"_blank"}>(클릭)</Link></li>
                            </ul>
                        </article>


                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <>
                {this.renderPrivacy()}
            </>
        );
    }
}

export default Privacy;