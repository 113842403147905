import React, {Component} from 'react';
import './guide.scss';
//@ts-ignore
import MktCloud from "../assets/mktcloud.png";
//@ts-ignore
import DFINERY from "../assets/dfinery.png";
//@ts-ignore
import MobileIndex from "../assets/mobileindex.png";
//@ts-ignore
import TradingWorks from "../assets/tw.png";

interface GuideProps {
    pathname: (v: string) => void;
}

class Guide extends Component <GuideProps> {

    componentDidMount() {
        this.props.pathname('guide');
    }

    private renderGuide = () => {
        return (<div className="cont-policy">
            <div className="container">
                <header className="cont-header">
                    <h3 className="title-policy">㈜아이지에이웍스의 저작권정책(“데이터 활용 가이드라인”)</h3>
                </header>
                <div className="wrap-policy-group">
                    <article className="article-policy">
                        <p>본 저작권정책은 아이지에이웍스의 서비스를 통해 제공되는 자료와 컨텐츠 등에 대한 아이지에이웍스의 권리를 보호하고 바람직한 활용 방법을 제시하고자 마련되었습니다. 본
                            정책이 적용되는 아이지에이웍스의 서비스는 다음과 같습니다.</p>
                    </article>

                    <article className="article-policy">
                        <section className={'logo-wrapper'}>
                            <div>
                                <div className={'logo none-ratio'}>
                                    <img src={MktCloud} alt={"mktcloud logo"}/>
                                </div>
                                <span>마클<br/>(마케팅클라우드)</span>
                            </div>
                            <div>
                                <div className={'logo'}>
                                    <img src={DFINERY} alt={"dfinery logo"}/>
                                </div>
                                <span>디파이너리<br/>(DFINERY)</span>
                            </div>
                            <div>
                                <div className={'logo'}>
                                    <img src={MobileIndex} alt={"mobileindex logo"}/>
                                </div>
                                <span>모바일인덱스<br/>(MobileIndex)</span>
                            </div>
                            <div>
                                <div className={'logo'}>
                                    <img src={TradingWorks} alt={"tradingworks logo"}/>
                                </div>
                                <span>트레이딩웍스<br/>(TradingWorks)</span>
                            </div>
                        </section>

                        <article className="article-policy">
                            <h5 className="title-article">아이지에이웍스의 저작권을 보호해 주세요.</h5>

                            <p>아이지에이웍스의 각 서비스를 통해 제공되는 모든 자료, 데이터, 컨텐츠 등(이하 “IGAW 저작물”)은 저작권법에 의하여 보호받는 저작물로 모든 권리는
                                아이지에이웍스에 있습니다.</p>
                            <p>따라서 아이지에이웍스는 IGAW 저작물의 위조, 변조, 복제, 배포, 제공 등 저작권법에 위배되는 저작권 침해 행위를 엄격히 금지하고 있으며, 위반 시에는 관련
                                법령에 따라 민·형사 상의 책임을 지게 될 수 있습니다.</p>
                        </article>
                    </article>

                    <article className="article-policy">
                        <h5 className="title-article">IGAW 저작물 활용 시 아래의 가이드라인을 준수해 주세요.</h5>
                        <p>{"IGAW 저작물은 저작권법이 허용하고 있는 범위 내에서 이용될 수 있습니다.\n" +
                            "IGAW 저작물을 활용하는 경우에는 다음의 사항을 반드시 준수해 주시기 바랍니다."}
                        </p>

                        <ul className="basic-number-item-list">
                            <li className="basic-number-item">IGAW 저작물이 공표된 경우 ‘보도ㆍ비평ㆍ교육ㆍ연구 등’의 목적으로 ‘정당한 범위에서 공정한 관행에
                                합치되게 인용’될 수 있습니다.
                                <p>‘정당한 범위에서 공정한 관행에 합치되게 인용’했는 지의 판단은 인용의 목적, 저작물의 성질, 인용된 내용과 분량, 피인용저작물을 수록한 방법과 형태,
                                    독자의 일반적 관념, 원저작물에 대한 수요를 대체하는지의 여부 등을 종합적으로 고려하여 판단하게 됩니다.</p>
                            </li>
                            <li className="basic-number-item">출처를 밝혀 활용한 저작물이 아이지에이웍스의 것이라는 점을 분명히 하여야 합니다.
                                <p></p>
                                <div className="sub-list">
                                    <p className={"title"} style={{fontWeight: "bold"}}>[출처 표기 예시<span
                                        style={{fontWeight: "normal"}}>(출처가 '모바일인덱스'인 경우)</span>]<br/></p>

                                    <ul className="basic-line-item-list">
                                        <li className="basic-line-item">보도자료
                                            <ul className="black-circle-item-list">
                                                <li className="black-circle-item">아이지에이웍스의 ‘모바일인덱스’에 따르면</li>
                                            </ul>
                                        </li>

                                        <li className="basic-line-item">기타발행물
                                            <ul className="black-circle-item-list">
                                                <li className="black-circle-item">출처: 아이지에이웍스의 ‘모바일인덱스’</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <p></p>
                            <li className="basic-number-item">활용되는 IGAW 저작물이 모바일인덱스 또는 마케팅클라우드를 통해 제공되는 정보인 경우, 그 범위와 정보
                                정의를 정확히 표기해야 합니다.
                                <p></p>
                                <div className="sub-list">
                                    <ul className="black-circle-item-list">
                                        <li>제공범위 : Android OS 또는 iOS 또는 Android OS+iOS</li>
                                        <li>신규 설치기기 : 동일 사용자의 재설치를 배제한 앱 설치 수</li>
                                        <li>총 설치기기 : 특정 시점, 앱이 설치된 모바일 단말기 수</li>
                                        <li>사용자 수 일간(DAU) : 일 순 방문자 수</li>
                                        <li>사용자 수 주간(WAU) : 주 순 방문자 수</li>
                                        <li>사용자 수 월간(MAU) : 월 순 방문자 수</li>
                                        <li>사용률 : 사용자수 / 총 설치기기</li>
                                        <li>1인당 평균 사용시간 일간 : 해당 일의 총 사용시간 / DAU</li>
                                        <li>1인당 평균 사용시간 주간 : 해당 주의 총 사용시간 / WAU</li>
                                        <li>1인당 평균 사용시간 월간 : 해당 월의 총 사용시간 / MAU</li>
                                    </ul>
                                </div>
                            </li>
                            <li className="basic-number-item">언론사의 요청에 따라 아이지에이웍스가 직접 데이터를 제공하는 경우를 제외하고, 아이지에이웍스가 직접
                                데이터를 제공한 것으로 보일 수 있는 문구나 표현은 사용되어서는 안 됩니다. 아이지에이웍스의 서비스 관련 정기/부정기 리포트는 오로지 ‘공공의 이익을 위한
                                목적’으로 배포되는 것일 뿐, 언론을 통한 기사화를 목적으로 제공되지 않습니다.
                            </li>
                            <li className="basic-number-item">IGAW 저작물은 타인을 비방 또는 비하하거나 타인의 명예를 훼손할 목적으로 사용되어서는 안 됩니다.
                            </li>
                            <li className="basic-number-item">아래는 저작권 침해 및 관련 법령에 저촉되는 대표적 행위이며, 아래의 행위 및 그 외 관련 법령에
                                저촉하여 IGAW 저작물을 활용하는 행위는 엄격히 금지되며, 위반 시에는 민·형사상의 책임을 지게 될 수 있습니다.
                                <p></p>
                                <div className="sub-list">
                                    <ul className="black-circle-item-list">
                                        <li>IGAW 저작물을 무단 사용, 위조, 변조, 가공 또는 변형하는 행위</li>
                                        <li>IGAW 저작물을 (재)판매하는 행위</li>
                                        <li>IGAW 저작물을 상업적으로 이용하는 행위</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </article>


                    <article className="article-policy">
                        <h5 className="title-article">아이지에이웍스는 IGAW 저작물을 활용함에 따라 발생한 손해 등에 대한 책임을 부담하지 않음에 유의해 주세요.</h5>
                        <p>IGAW 저작물은 아이지에이웍스의 독자적인 추정 알고리즘 기술 및 머신러닝 기법 등을 통해 생성되는 추정 데이터입니다. 아이지에이웍스는 IGAW 저작물의 정확성,
                            신뢰성 또는 적법성 등에 관한 어떠한 보증도 하지 않으므로, IGAW 저작물은 참고자료로 활용해 주시기 바랍니다. </p>
                        <p>특히, 모바일인덱스와 마케팅클라우드에서 제공하는 모든 정보, 데이터, 컨텐츠는 아이지에이웍스의 추정 알고리즘 및 머신러닝 기술이 240여개 카테고리로 세분화된 국내
                            30,000여개 앱의 Usage Data(이용성 데이터)와 한국 모바일 앱 마켓 3사(원스토어,구글플레이,앱스토어)의 App Market Data(매출 추정치
                            데이터) 등에 대하여 분석한 추정 결과 값이므로 실제 데이터와 다를 수 있고, 데이터의 세부 정의에 따라 동일한 이름의 데이터임에도 차이가 있을 수 있습니다.
                            또한 아이지에이웍스 자체 알고리즘 변경에 따른 재추정으로 인해 데이터 수치 변동 있을 수 있습니다. 아이지에이웍스는 이러한 차이로 인한 어떠한 손해에 대하여도 책임을 지지 않습니다.</p>
                        <p>IGAW 저작물을 활용하거나 신뢰함으로써 발생한 손해 등에 대하여 아이지에이웍스는 어떠한 책임이나 의무도 부담하지 않으며, 이와 관련하여 발생하는 분쟁 등에 대하여도
                            개입 의무가 없으며 이로 인한 손해에 대하여 책임지지 않습니다. </p>
                    </article>


                    <article className="article-policy">
                        <p>본 정책 및 IGAW 저작물 활용에 대한 자세한 문의는 ㈜아이지에이웍스(help@igaworks.com)로 연락주시기 바랍니다.</p>
                    </article>
                </div>
            </div>
        </div>)
    }

    render() {
        return (
            <>
                {this.renderGuide()}
            </>
        );
    }
}

export default Guide;